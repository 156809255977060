import { accountActionTypes, dataActionTypes } from "../constants/ActionTypes";

const initialState = {
    accountInfo: {},
    prioritiesList: {
        data: [],
        count: 0
    },
    statusList: {
        data: [],
        count: 0
    },
    KPIData : {},
    notificationList: {
        data: [],
        count: 0
    },
    ticketType : {
        data: [],
        count: 0
    },
    allTicketList:{
        data: [],
        count: 0
    }
}

export const accountReducer = (state = initialState, { type, payload,isReset }: any) => {
    switch (type) {
        case dataActionTypes.FETCH_TICKET_PRIORITIES: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset !== false
                ? queryResponse
                : [...state.prioritiesList.data, ...queryResponse];
        
            return {
                ...state,
                prioritiesList: {
                    data: data,
                    count: payload.totalrecords || state.prioritiesList.count,
                },
            };
        }
        case dataActionTypes.FETCH_TICKET_STATUS: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset !== false
                ? queryResponse
                : [...state.statusList.data, ...queryResponse];
        
            return {
                ...state,
                statusList: {
                    data: data,
                    count: payload.totalrecords || state.statusList.count,
                },
            };
        }
        case dataActionTypes.FETCH_TICKET_TYPE: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset !== false
                ? queryResponse
                : [...state.ticketType.data, ...queryResponse];
        
            return {
                ...state,
                ticketType: {
                    data: data,
                    count: payload.totalrecords || state.ticketType.count,
                },
            };
        }
        case dataActionTypes.FETCH_ALL_TICKETS: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset !== false
                ? queryResponse
                : [...state.allTicketList.data, ...queryResponse];
        
            return {
                ...state,
                allTicketList: {
                    data: data,
                    count: payload.totalrecords || state.allTicketList.count,
                },
            };
        }
        case dataActionTypes.FEATCH_KPI_DATA:
            return { ...state, KPIData: payload.queryResponse.data }
        case accountActionTypes.USER_RESET_PASSWORD_LINK:
            return { ...state, resetData: payload }
        case accountActionTypes.GET_PROFILE_INFO:
            return { ...state, profileData: payload.data }
        case accountActionTypes.UPDATE_PROFILE: 
            return { ...state, ...payload }
        case accountActionTypes.FETCH_NOTIFICATION_LIST: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset
                ? queryResponse // Replace existing data if `isReset` is true
                : [...state.notificationList.data, ...queryResponse]; // Append new data to existing data

            return {
                ...state,
                notificationList: {
                    data: data,
                    count: payload.totalrecords || state.notificationList.count, // Update count or fallback to existing count
                },
            };
        }
        default:
            return state

    }
} 