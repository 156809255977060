import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import './assets/css/common.scss';
import { Provider } from 'react-redux';
import { Notify } from './context';
import store from './redux/store';
import { RootRoutes } from './routers';

function App() {
  return (
    <Provider store={store}>
      <Notify>
        <RootRoutes />
      </Notify>
    </Provider>
  );
}

export default App;