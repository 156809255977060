import React from 'react';
import { errorMessage } from '../components/messages';
import { SnackbarProvider, useSnackbar } from 'notistack';

const NotifyContext = React.createContext({
    success: (msg: any) => { },
    error: (msg: any) => { },
    warning: (msg: any) => { },
    info: (msg: any) => { },
});

let handleNotify: any;
const handleSuccess = (msg: any) => (handleNotify(msg, "success"));
export const handleError = (msg: any) => (handleNotify(msg, "error"));
const handleWarning = (msg: any) => (handleNotify(msg, "warning"));
export const handleInfo = (msg: any) => (handleNotify(msg, "info"));

function NotifyComponent(props: any) {
    const { enqueueSnackbar } = useSnackbar();

    handleNotify = function (msg: any, variant: any) {
        enqueueSnackbar(msg, {
            variant,
            preventDuplicate: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
    }

    return (
        <NotifyContext.Provider value={{
            success: handleSuccess,
            error: handleError,
            warning: handleWarning,
            info: handleInfo,
        }}>
            {props.children}
        </NotifyContext.Provider>
    )
}

function ShowErrors(items: any[]) {
    let list;
    if(items[0]?.includes(401)){
        list = <div>{errorMessage.error401}</div>;
    }else{
        list = <div>{items[0]}</div>;
    }
    handleError(list);
}

function ShowSuccess(items: any) {
    let list = <div>{items}</div>;
    handleSuccess(list);
}
function errorMsg(list:any){
    handleError(list);
}

const notistackRef:any = React.createRef();

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: any) {
    return (
        <SnackbarProvider
            autoHideDuration={1500}
            maxSnack={4}
            preventDuplicate
            ref={notistackRef}
        >
            <NotifyComponent>{props.children}</NotifyComponent>
        </SnackbarProvider>
    )
}
export {
    NotifyContext,
    ShowErrors as Errors,
    ShowSuccess as Success,
    errorMsg as errorMsgs
}