import { FC, useEffect, useState } from "react";
import ChatWindow from "./ChatWindow";
import { Errors, Success } from "../../context/notify";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import {
  Message,
  Messages,
  UserMaster,
  MessagesModel,
  ReceiversDetails,
} from "../../Typings/dataListing";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import {
  fetchGetAllMessages,
  GetAllUsersList,
  getAllAdminList,
} from "../../redux/actions/chatAction";

interface User {
  UserId: number;
  IsAdmin: boolean;
}

const ChatApp: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [skipRow, setSkipRow] = useState(0);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [message, setMessage] = useState<Message[]>([]);
  const [unreadCounts, setUnreadCounts] = useState<Record<string, number>>({});
  const [currentUser, SetcurrentUser] = useState<User>();
  const [UserMode, SetUsermodel] = useState<UserMaster>();
  const [Users, SetUsers] = useState<Array<{ label: string; value: number }>>(
    []
  );
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [selectedUserName, setSelectedUserName] = useState<string | null>("");
  const userType = localStorage.getItem("role");
  var UserisAdmin = userType == "Admin";

  // const baseURL = process.env.REACT_APP_API_SERVER;
  const baseURL = "https://localhost:44311";

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(baseURL + "/chatHub")
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    manageCurrentUser();
  }, [dispatch]);

  const manageCurrentUser = async () => {
    const userId = parseInt(localStorage.getItem("userId") || "0", 0);
    const Use: User = {
      IsAdmin: UserisAdmin,
      UserId: userId,
    };
    SetcurrentUser(Use);
  };

  const GetAllUsers = async () => {
    dispatch(
      GetAllUsersList((response: any) => {
        if (response && response.data && Array.isArray(response.data)) {
          const FormatUsers = response.data.map((item: any) => ({
            label: item.fullName,
            value: item.userid,
          }));
          SetUsers(FormatUsers);
        }
      })
    );
  };

  const getAllAdmin = async () => {
    dispatch(
      getAllAdminList((response: any) => {
        if (response && response.data && Array.isArray(response.data)) {
          const FormatUsers = response.data.map((item: any) => ({
            label: item.fullName,
            value: item.userid,
          }));
          SetUsers(FormatUsers);
        }
      })
    );
  };

  const fetchMessages = (userid: number) => {
    var payload = {
      UserId: currentUser?.UserId,
      SkipRow: skipRow,
      selecteduser: userid,
    };

    try {
      dispatch(
        fetchGetAllMessages(payload, (response: any) => {
          if (!response || !Array.isArray(response.data)) {
            console.error("Invalid response format: ", response);
            return;
          }

          setMessages((prevMessages) => [...prevMessages, ...response.data]);
          const messageList: Messages[] = response.data;

          messageList.forEach((msg) => {
            if (!msg.isRead && msg.senderId === selectedUser) {
              updateUnreadCount(msg.senderId);
            }
          });

          const newMessages = messageList.map((msg, index) => ({
            id: message.length + index + 1,
            user:
              msg.senderId === currentUser?.UserId ? "Admin" : selectedUserName,
            text: msg.messageText,
          }));

          setMessage((prevMessages) => [...prevMessages, ...newMessages]);
        })
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(async () => {
          await connection.invoke("RegisterConnection", currentUser?.UserId);

          if (UserisAdmin) {
            GetAllUsers();
          }
          if (!UserisAdmin) {
            getAllAdmin();
            const LUsername = localStorage.getItem("lusername");
          }
        })
        .catch(( error : any) => console.log("Connection failed: ", error));
      setupSignalRHandlers();
    }
  }, [connection, currentUser]);

  const setupSignalRHandlers = () => {
    if (!connection) return;

    connection.on("ReceiveMessage", (MessagesModel: MessagesModel) => {
      if (!MessagesModel) {
        console.error("Expected an array but got:", MessagesModel);
        return;
      }
      // console.log(MessagesModel);
      SetReceivedMessages(MessagesModel);
    });

    connection.on("UserConnected", (user: UserMaster) => {
      SetUsermodel(user);
      const ConnectedUserMessage =
        user.firstName + " " + user.lastName + " Online";
      Success(ConnectedUserMessage);
    });
  };

  const SetReceivedMessages = async (MessagesModel: MessagesModel) => {
    // console.log("sendRRRRRRmessage",selectedUser, selectedUserName);
    if(selectedUser==0) return;
   
   if (!MessagesModel) {
     console.error("Invalid messages format:", MessagesModel);
     return;
   }
    // console.log(selectedUser, selectedUserName);
    const messages: Messages = MessagesModel.message;
    const newMessages: Message = {
      id: message.length + 1,
      user:
        messages.senderId === currentUser?.UserId ? "Admin" : selectedUserName,
      text: messages.messageText,
    };
    const Receivers: ReceiversDetails = MessagesModel.receivers;

    if (messages.senderId == currentUser?.UserId) {
      if (Receivers.receiversId == selectedUser) {
        // console.log(1)
        setMessage((prevMessages) => [newMessages, ...prevMessages]);
      } else if (messages.senderId != currentUser.UserId){
        const ReceivedmessageNotification =
          "New message from " + Receivers.receiverName;
        Success(ReceivedmessageNotification);
        // console.log(2)

      }
    }
    if (Receivers.receiversId == currentUser?.UserId) {
      if(messages.senderId == selectedUser){
        // console.log(3)

        setMessage((prevMessages) => [newMessages, ...prevMessages]);
      }
      else if(messages.senderId != selectedUser){
        const ReceivedmessageNotification =
          "New message";
        Success(ReceivedmessageNotification);
        // console.log(4)
      }
    }
  };

  const sendMessage = async (text: string) => {
    // console.log("sendmessage",selectedUser, selectedUserName);
    if (connection && message && (selectedUser || !currentUser?.IsAdmin)) {
      try {

        const receiverId = selectedUser;
        const senderId = currentUser?.UserId;
        if (receiverId) {
          await connection.invoke("SendMessage", receiverId, senderId, text);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (selectedUser !== 0) {
      // Setup your SignalR connection and handlers
      if(connection)
        connection.on("ReceiveMessage", (MessagesModel: MessagesModel) => {
        // Call the message handling function when a message is received
        SetReceivedMessages(MessagesModel);
      });
    }
  }, [selectedUser]); 
  const handleUserClick = (UserName: string, UserId: number) => {
    setSelectedUser(UserId);
    setSelectedUserName(UserName);
    
    setMessage([]);
    fetchMessages(UserId);  
  
  };
  const updateUnreadCount = (senderId: number) => {
    setUnreadCounts((prev) => ({
      ...prev,
      [senderId]: (prev[senderId] || 0) + 1,
    }));
  };

  return (
    <div className="chat-container">
      <div className="user-list">
        <h3>Users</h3>
        <div className="user-list-container">
          {Users.map((user) => (
            <div
              key={user.value}
              className="user-item"
              onClick={() => handleUserClick(user.label, user.value)}
            >
              {user.label}
            </div>
          ))}
        </div>
      </div>
      <div className="chat-window">
        <ChatWindow
          messages={message}
          sendMessage={sendMessage}
          Username={selectedUserName}
        />
      </div>
    </div>
  );
};

export default ChatApp;
