import { accountActionTypes } from "../constants/ActionTypes";

const initialState = {
    accountInfo: {},
}

export const accountReducer = (state = initialState, { type, payload,isReset }: any) => {
    switch (type) {
        case accountActionTypes.USER_RESET_PASSWORD_LINK:
            return { ...state, resetData: payload }
        case accountActionTypes.GET_PROFILE_INFO:
            return { ...state, profileData: payload.data }
        case accountActionTypes.UPDATE_PROFILE:
            return { ...state, ...payload }
        default:
            return state

    }
} 