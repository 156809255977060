export default {
client: {
    ClientList:  "api/Client/GetAllUsersList",
    AddClient:  "api/Admin/AddUserPrimaryDetail",
    GetClientByClientId: "api/Client/GetUserByUserId"
},
Project: {
    ProjectList:"api/Project/getAllProjects",
    AddUpdateProject: "api/Project/AddUpdateProjectDetails",
    GetProjectDetails: "api/Project/getProjectsbyProjectId"
}
};