import React, { useState, useRef, useEffect } from "react";
import { Message } from "../../Typings/dataListing";

interface ChatWindowProps {
  messages: Message[];
  sendMessage: (message: string) => void;
  Username: string | null;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  sendMessage,
  Username,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [input, setInput] = useState("");
  const userType = localStorage.getItem("role");
  const headerMessage = Username || "Select a user";
  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input);
      setInput("");
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  return (
    <div className="chat-window">
      <div className="chat-header">
        <span>{headerMessage}</span>
      </div>
      <div className="messages">
        {[...messages].reverse().map((msg,index) => (
          <div
            key={msg.user + "_"+ msg.id + "_" + index}
            className={`message ${msg.user === "Admin" ? "admin" : ""}`}
          >
            <strong></strong> {msg.text}
          </div>
        ))}
        <div ref={messagesEndRef} /> 
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default ChatWindow;
