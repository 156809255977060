import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserImg from '../../assets/img/user-img1.jpg';
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { PageSize } from "../../config/constant";
import { fetchNotificationList, ReadNotification } from "../../redux/actions/accountAction";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";

interface ToggleMenuProps {
  onClick: () => void; 
}

const DashboardHeader: React.FC<ToggleMenuProps> = ({ onClick }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isNotificationMenuVisible, setNotificationMenuVisible] = useState(false);
  const notificationList = useSelector((state: RootState) => state.accountData.notificationList);

  useEffect(() => {
    getNotifications(true);
  }, [dispatch]);

  const toggleMenu = () => {
    onClick();
  };
 
  const toggleNotificationMenu = () => {
    setNotificationMenuVisible((prev) => !prev);
  };

  const [defaultParamsForNotification, setDefaultParamsForNotification] = useState({
    Userid: parseInt(localStorage.getItem("userId") || "0", 10),
    PageIndex: 1,
    PageSize: PageSize,
  } as any);
  let totalRecordCountForNotification = notificationList.count;
  const currentDefaultParamsForNotification = defaultParamsForNotification;

  const getNotifications = (isReset: boolean = false) => {
    currentDefaultParamsForNotification.PageIndex = isReset ? 1 : currentDefaultParamsForNotification.PageIndex + 1;
    setDefaultParamsForNotification(currentDefaultParamsForNotification);
    dispatch(fetchNotificationList(currentDefaultParamsForNotification, isReset));
  }

  const readAllNotification = () => {
    dispatch(ReadNotification({Userid:parseInt(localStorage.getItem("userId") || "0", 10),notificationId:0},));
  }
  return (
    <>
      <div className="me-2 me-md-4">
        <a href="#" className="notificationLink d-flex" onClick={toggleNotificationMenu}>
          <span className="material-icons icon">notifications</span>
          <span className="status"></span>
        </a>
        {isNotificationMenuVisible && (
        <div className="notification-menu">
          <div className="menu-header d-flex justify-content-between align-items-center">
            <span>Notifications</span>
            <button
              className="mark-as-read-btn btn btn-sm btn-primary"
              onClick={() => readAllNotification()}
            >            
              Mark All as Read
            </button>
            <button className="btn btn-sm btn-warning" onClick={toggleNotificationMenu}><i className="fa fa-close"></i></button>
          </div>
          <div id="scrollableDiv2" style={{ height: "calc(100vh - 60Vh)", overflow: "auto" }}>
            <ul className="notification-list">
                <InfiniteScroll
                  dataLength={notificationList.data.length}
                  next={() => { getNotifications() }}
                  hasMore={totalRecordCountForNotification !== notificationList.data.length}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv2"
                  scrollThreshold="200px"
                >  
                  {notificationList.data.map((items: any,index:any) => (
                    <li className="notification-item" key={index}>
                      <div className="notification-text">
                        <Link to={items.notificationActionUrl} >
                          {items.notificationTitle}
                        </Link>
                      </div>
                      <div className="notification-message">{items.notificationMessage}</div>
                      {index < notificationList.data.length - 1 && <hr className="notification-divider" />}
                    </li>
                  ))}
                </InfiniteScroll>
            </ul>
          </div>
        </div>
      )}
      </div>
      {/* <div className="me-2 me-md-4">
        <a href="#" className="mailLink d-flex">
          <span className="material-icons icon">email</span>
        </a> 
      </div> */}
      <div className="acLinkBox">
        <a href="#" className="acLink d-flex align-items-center">
          <img src={UserImg} alt="" className="img me-2" /> 
          <span className="text me-2">{ localStorage.getItem("lusername") ? localStorage.getItem("lusername") :"Turn Stone"}</span> 
          {/* <span className="material-icons arrow">expand_more</span> */}
        </a>
      </div>
      <a className="menuToggle" onClick={toggleMenu}>
          <span className="material-icons">menu</span>
      </a>
    </>
  );
} 

export default DashboardHeader;