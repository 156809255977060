import { FC, useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import { string, object, date, ref } from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { errorMsgs } from "../../context/notify";
import { FormikProps, useFormik } from "formik";
import { AddUpdateProjectData } from "../../Typings/dataListing";
import { formFields } from "../../components/messages";
import {
  AddProjectDetails,
  GetprojectDetails,
} from "../../redux/actions/ProjectAction";
import { checkboxClasses } from "@mui/material";

type SelectedRadio = {
  name: string;
  value: string;
} | null;

const CreateProject: FC = () => {
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const [isActive, setisActive] = useState(false);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    getProjectdate();
  }, [dispatch]);

  const getProjectdate = async () => {
    var payload = {
      projectId: Number(projectId),
    };
    try {
      dispatch(
        GetprojectDetails(payload, (data: any) => {
          const projectData = data?.data;
          if (projectData) {
            formik.setValues({
              id: projectData.projectId || 0,
              name: projectData.name || "",
              parentId: projectData.parentId || "",
              homePageURL: projectData.homePageURL || "",
              description: projectData.description || "",
              projectNumber: projectData.projectNumber || "",
              city: projectData.city || "",
              street: projectData.street || "",
              zipCode: projectData.zipcode || "",
              projectStartDate: new Date(projectData.projectStartDate)
                .toISOString()
                .split("T")[0],
              projectEndDate: new Date(projectData.projectEndDate)
                .toISOString()
                .split("T")[0],
              createdBy: projectData.createdBy || 0,
              lastUpdatedBy: projectData.lastUpdatedBy || 0,
              active: !!projectData.isActive
            });
          }
        })
      );
    } catch (error) {
      console.error("Error fetching user types:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik: FormikProps<AddUpdateProjectData> = useFormik({
    initialValues: {
      id: 0,
      name: "",
      parentId: "",
      homePageURL: "",
      description: "",
      projectNumber: "",
      city: "",
      street: "",
      zipCode: "",
      projectStartDate: "",
      projectEndDate: "",
      createdBy: 1,
      lastUpdatedBy: 1,
      active : Boolean(null)
    },
    validationSchema: object({
      name: string().required("Project name is required"),
      projectNumber: string().required("Project number is required"),
      projectStartDate: date().required("Start date is required"),
      projectEndDate: date()
        .required("End date is required")
        .min(ref("projectStartDate"), "End date must be after the start date"),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      console.log(values);
      setIsShowLoader(true);
      dispatch(
        AddProjectDetails(values, (response: any) => {
          formik.resetForm();
          try {
            if (response.statusCode === 0) {
              errorMsgs(response.message);
            } else if (response.statusCode === 1) {
              navigate("/project");
            } else if (response.statusCode === 2) {
              navigate("/project");
            }
          } catch (error) {
          } finally {
            setSubmitting(false);
            setIsShowLoader(false);
          }
          setIsShowLoader(false);
        })
      );
      setIsShowLoader(false);
    },
  });

  const toggleMenu = () => {
    setisActive(!isActive);
  };

  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Create New Project</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <form onSubmit={formik.handleSubmit}>
            <div className="createForm p-3 p-sm-5">
              <ul className="form d-flex flex-wrap">
                {/* Project Name */}
                <li className="col-12 col-sm-6">
                  <label className="fieldName">Project Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`formInput ${
                      formik.errors.name && formik.touched.name
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  )}
                </li>

                {/* Project Number */}
                <li className="col-12 col-sm-6">
                  <label className="fieldName">Project Number</label>
                  <input
                    type="text"
                    name="projectNumber"
                    value={formik.values.projectNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`formInput ${
                      formik.errors.projectNumber &&
                      formik.touched.projectNumber
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors.projectNumber &&
                    formik.touched.projectNumber && (
                      <div className="invalid-feedback">
                        {formik.errors.projectNumber}
                      </div>
                    )}
                </li>

                <li className="col-12">
                  <label className="fieldName">Description</label>
                  <textarea
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="formInput"
                  />
                </li>

                {/* Other Fields */}
                <li className="col-12 col-sm-6">
                  <label className="fieldName">Home Page URL</label>
                  <input
                    type="text"
                    name="homePageURL"
                    value={formik.values.homePageURL}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="formInput"
                  />
                </li>

                <li className="col-12 col-sm-6">
                  <label className="fieldName">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="formInput"
                  />
                </li>

                <li className="col-12 col-sm-6">
                  <label className="fieldName">Street</label>
                  <input
                    type="text"
                    name="street"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="formInput"
                  />
                </li>

                <li className="col-12 col-sm-6">
                  <label className="fieldName">Zip Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`formInput ${
                      formik.errors.zipCode && formik.touched.zipCode
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors.zipCode && formik.touched.zipCode && (
                    <div className="invalid-feedback">
                      {formik.errors.zipCode}
                    </div>
                  )}
                </li>

                {/* Other fields similar to the above */}
                {/* Example: Parent Id */}
                <li className="col-12 col-sm-6">
                  <label className="fieldName">Parent Id</label>
                  <input
                    type="text"
                    name="parentId"
                    value={formik.values.parentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="formInput"
                  />
                </li>

                {/* Start and End Dates */}
                <li className="col-12 col-sm-6">
                  <label className="fieldName">Start Date</label>
                  <input
                    type="date"
                    name="projectStartDate"
                    value={formik.values.projectStartDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`formInput ${
                      formik.errors.projectStartDate &&
                      formik.touched.projectStartDate
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors.projectStartDate &&
                    formik.touched.projectStartDate && (
                      <div className="invalid-feedback">
                        {formik.errors.projectStartDate}
                      </div>
                    )}
                </li>

                <li className="col-12 col-sm-6">
                  <label className="fieldName">End Date</label>
                  <input
                    type="date"
                    name="projectEndDate"
                    value={formik.values.projectEndDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`formInput ${
                      formik.errors.projectEndDate &&
                      formik.touched.projectEndDate
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors.projectEndDate &&
                    formik.touched.projectEndDate && (
                      <div className="invalid-feedback">
                        {formik.errors.projectEndDate}
                      </div>
                    )}
                </li>

                <li className="col-12 col-sm-6">
                  <label className="fieldName">Active</label>
                  <div className="d-flex align-items-center">
                    <label className="optionField d-flex align-items-center">
                      <input
                        type="checkbox"
                        name="Active"
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue("Active", e.target.checked)}
                        onBlur={formik.handleBlur}
                      />
                      Yes
                    </label>
                  </div>
                </li>

                {/* Submit Button */}
                <li className="col-12 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="formBtn position-relative ms-3"
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateProject;
