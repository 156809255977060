import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import Pagination from "../../components/pagination";
import { Link } from "react-router-dom";
import { bool, number } from "yup";
import { AppDispatch, RootState } from "../../redux/store";
import { PageSize, PageSize2 } from "../../config/constant";
import { activeDeactiveProject, deleteProject, GetProjects, getprojectUserIdAsync } from "../../redux/actions/ProjectAction";
import { Success } from "../../context/notify";
import { assignProjects, fetchClientList } from "../../redux/actions/clientAction";
import { Button, Dialog, DialogActions } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetAllTicketByProjectId } from "../../redux/actions/ticketAction";

const ManageProject: FC = () => {
   const navigate = useNavigate();
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [ProjectList, SetProjectList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  const dispatch = useDispatch<AppDispatch>();
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [sortBy, setSortBy] = useState("projectId"); // column to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [openAssignProject, setOpenAssignProject] = useState(false);
  const [listRef, setListRef] = useState(0);
  const [projectId, setPrpjectId] = useState("");
  const clientList = useSelector((state: RootState) => state.clientData.clientList);
  const [openViewProject, setopenViewProject] = useState(false);
  const [projectViewData, setProjectViewData] = useState(Object);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true); 
  const [assignClienttId, setAssignClienttId] = useState<number[]>([]);
  const [pageSizeSelect, setPageSizeSelect] = useState(PageSize2);
  useEffect(() => {
    if (initialLoad) {
      setIsLoading(true);
      searchProject();
      setInitialLoad(false); // Set guard variable to false after initial call
    }else {
      searchProject(); // Call when pageSizeSelect or other dependencies change
    }
  }, [CurrentPage, searchQuery,pageSizeSelect]);

  const searchProject = (column: string = sortBy, order: string = sortOrder) => {
    var payload = {
      PageNumber: CurrentPage,
      PageSize: pageSizeSelect,
      Search: isSearch ? "" : searchText,
      SortColumn: column,
      SortOrder: order,
    };
    try {
      dispatch(
        GetProjects(payload, (response:any) => {
          SetProjectList(response.data);
        })
      );
    } catch (error) {
        console.error("Error fetching Client:", error);
    } finally {
      setTimeout(() => {
          setIsLoading(false);
      },2000);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const closeSearch = () =>{
    SetCurrentPage(1); // Reset pagination to the first page
    setSearchText(""); // Clear search input value
    setSearchQuery(""); // Clear the query used for filtering
    setIsSearch(false); // Exit search mode
    searchProject();
  }

  const handleSort = (column: string) => {
    const order = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column); // Set the column to sort by
    setSortOrder(order); // Toggle the sorting order
    searchProject(column, order);
  };

  const handleToggle = (id:any, isActive:any) => {
    SetProjectList((prevState:any) => ({
      ...prevState,
      queryResponse: prevState.queryResponse.map((item:any) =>
        item.projectId === id ? { ...item, isActive } : item
      ),
    }));
    const userId = parseInt(localStorage.getItem("userId") || "0", 10);
    dispatch(activeDeactiveProject({ AppProjectId: id, status: isActive, userId:userId }, 
      (response: any) => projectStatusResponse(response)));
  };

  function projectStatusResponse(response: any) {
    Success(response.data.message);
  }
  
  const handleProjectAssignOpen = (id: string) => {
    dispatch(getprojectUserIdAsync({Userid:0,Projectid:id}, (response: any) => callbackProjectCheck(response)))
    if (id) setPrpjectId(id);
  };

  function callbackProjectCheck(response: any)
  { 
    if(response.data){
      const clientId = response.data.split(',').map((id: string) => parseInt(id.trim())); // Parse the string response into an array of numbers
      setAssignClienttId(clientId);
    }else{
      setAssignClienttId([]);
    }
    getClientList(true);
  } 

  const handleClose = () => {
    setOpenAssignProject(false);
    setIsConfirmOpen(false);
  };

  const [defaultParamsForUsers, setDefaultParamsForUsers] = useState({
    PageNumber: 1,
    PageSize: PageSize,
  } as any);
  let totalRecordCountForClient = clientList.count;
  const currentDefaultParamsForUsers = defaultParamsForUsers;

  const handleAfterSubmit = (resetForm?: any, isSubmit?: Boolean) => {
    resetForm();
    setOpenAssignProject(false);
  }

  const getClientList = (isReset: boolean = false) => {
    currentDefaultParamsForUsers.PageNumber = isReset ? 1 : currentDefaultParamsForUsers.PageNumber + 1;
    setDefaultParamsForUsers(currentDefaultParamsForUsers);
    dispatch(fetchClientList(currentDefaultParamsForUsers, isReset));
    setOpenAssignProject(true);
  }
  
  const getProjectFromSystem = (proId:any) =>{
    dispatch(GetAllTicketByProjectId(proId));
    navigate(`/tickets/${proId}`);
  }

  const handleViewProjectOpen = (datas: any) => {
    setProjectViewData(datas);
    setopenViewProject(true);
  };

  const handleDeleteProjectOpen = (projectId:any) =>{
    setPrpjectId(projectId);
    setIsConfirmOpen(true);
  }

  const handleConfirmDelete = () => {
    var payload = {
      AppProjectId: projectId,
      userId: parseInt(localStorage.getItem("userId") || "0", 10) 
    };
    dispatch(deleteProject(payload));
    setIsConfirmOpen(false);
  };

  const getUserAssignModal = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openAssignProject}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <div className="p-3">
            <div className="d-flex justify-content-between">
                <div className="d-block w-75">
                    <h4 className="board-title-h4">Select Client</h4>
                </div>
            </div>
            <div className="usergroup-container">
                <div className="mt-3">
                <Formik
                  initialValues={{
                    userId: assignClienttId, // Initialize with existing selections if applicable
                  }}
                  validationSchema={Yup.object({
                    userId: Yup.array().min(1, 'Need to select at least one project'),
                  })}
                  onSubmit={({ userId }) => {
                    const assignClientInfo = {
                      projectId: projectId,
                      userId: userId.join(','), // Convert the array to a comma-separated string
                    };
                    setListRef(listRef + 1);
                    dispatch(assignProjects(assignClientInfo));
                    setOpenAssignProject(false);
                  }}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <Form>
                      <div id="scrollableDiv2" style={{ height: "calc(100vh - 60Vh)", overflow: "auto" }}>
                        <InfiniteScroll
                          dataLength={clientList.data.length}
                          next={() => getClientList()}
                          hasMore={totalRecordCountForClient !== clientList.data.length}
                          loader={<Loader />}
                          scrollableTarget="scrollableDiv2"
                          scrollThreshold="200px"
                        >
                          <table className="table table-borderless">
                            <thead>
                              <tr className="header-row">
                                <th className="col-first">Select</th>
                                <th className="col-4">Name</th>
                                <th>Email</th>
                                <th>Company</th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientList.data.map((items: any) => (
                                <tr key={`client${items.userId}`}>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="checkbox"
                                        name={`userId`} // Keep the name consistent for Formik's management
                                        value={items.userId}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          const newValues = isChecked
                                            ? [...values.userId, items.userId] // Add to the array if checked
                                            : values.userId.filter((id) => id !== items.userId); // Remove if unchecked
                                          setFieldValue('userId', newValues); // Update Formik's state
                                        }}
                                        checked={values.userId.includes(items.userId)} // Check if selected
                                      />
                                      <label htmlFor={`user_${items.userId}`}></label>
                                    </div>
                                  </td>
                                  <td>{items.name}</td>
                                  <td>{items.email}</td>
                                  <td>{items.company}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                      <DialogActions>
                        <div className="float-end">
                          <Button
                            type="button"
                            className="close-button"
                            autoFocus
                            onClick={() => handleAfterSubmit(resetForm, true)}
                          >
                            Close
                          </Button>
                          <Button className="save-button ms-3" type="submit">
                            Assign
                          </Button>
                        </div>
                      </DialogActions>
                    </Form>
                  )}
                </Formik>

                </div>
            </div>
        </div>
    </Dialog>
    );
  };

  const getProjectViewDetails = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openViewProject}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3 ticket-details">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-3">View Project Details</h4>
                <p><b>Project Name :  </b>{projectViewData.name}</p>
                <p><b>Project Number :  </b>{projectViewData.projectNumber}</p>
                <p><b>Description :  </b>{projectViewData.description}</p>
                <p><b>City :  </b>{projectViewData.city}</p>
                <p><b>Street :  </b>{projectViewData.street}</p>
                <p><b>Zipcode :  </b>{projectViewData.zipcode}</p>
                <p><b>Active :  </b>{projectViewData.isActive ? "Active":"In Active"}</p>
                <p><b>Home Page URL :  </b>{projectViewData.homePageURL}</p>
                <p><b>Start Date :  </b>{new Date(projectViewData.projectStartDate).toLocaleDateString("en-GB")}</p>
                <p><b>End Date :  </b>{new Date(projectViewData.projectStartDate).toLocaleDateString("en-GB")}</p>
                <DialogActions>
                    <div className="float-end">
                        <Button
                            type="button"
                            className="close-button btn-warning"
                            autoFocus
                            onClick={() => setopenViewProject(false)}>
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const deleteProjectModel = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={isConfirmOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <div className="p-3">
            <div className="d-flex justify-content-between">
                <div className="d-block w-100">
                    <center><h4 className="board-title-h4">Delete Project</h4></center>
                </div>
            </div>
            <div className="row mt-2">
              <h5 className="text-center">Are you sure you want to delete this project?</h5>
              <div className="mt-3 col-md-12 ">
                <center>
                  <button
                      type="button"
                      className="btn btn-primary"
                      autoFocus
                      onClick={() => handleClose()}>
                      Cancel
                  </button>
                  <button
                      type="button"
                      className="btn btn-danger ms-2"
                      autoFocus
                      onClick={() => handleConfirmDelete()}>
                      Yes, Delete
                  </button>
                  </center>
              </div>
            </div>
        </div>
    </Dialog>
    );
  };

  const handlePageChange = (event: any) =>{
    const selectedPageSize = parseInt(event.target.value, 10);
    setPageSizeSelect(selectedPageSize); // Update page size
    SetCurrentPage(1); // Reset to the first page
  }
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Projects</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4">
              { localStorage.getItem("role") === "Admin" ? 
                <NavLink
                  to="/project/create-project"
                  className="createLink d-inline-flex align-items-center"
                >
                  Create
                </NavLink> : ""}
              </div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-3 pb-xl-3">
            {/* <ul className="d-flex flex-wrap projectTopInfo">
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={Project} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Total Projects <span style={{ color: "#115D8B" }}>11</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 175, 83, 0.19)" }}
                  >
                    <img src={ActiveProject} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Active Projects <span style={{ color: "#11AF53" }}>7</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(139, 28, 17, 0.25)" }}
                  >
                    <img src={InActiveProject} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    In Active Projects{" "}
                    <span style={{ color: "#CE4523" }}>3</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner d-flex align-items-center p-4 projectStatus">
                  <div className="left">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: "rgba(255, 233, 198, 1)" }}
                    >
                      <img src={ProjectStatus} alt="" />
                    </div>
                    <div className="det text-center pt-4">Project Status</div>
                  </div>
                  <div className="right ps-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>In Progress</span>
                      <span className="rgt" style={{ color: "#FF6600" }}>
                        4
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Pending</span>
                      <span className="rgt" style={{ color: "#115D8B" }}>
                        4
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Done</span>
                      <span className="rgt" style={{ color: "#138944" }}>
                        3
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul> */}
            <div className="filterCon d-flex flex-wrap align-items-center mt-4 mt-xl-2">
              <div className="filterBox filterSearchBox">
                <div className="filterSearch">
                  <input
                    type="text"
                    onChange={(event) => setSearchText(event.target.value)}
                    className="input"
                    placeholder="Search..."
                  />
                  {!isSearch ? 
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        SetCurrentPage(1);
                        setIsSearch(true);
                        searchProject();
                      }}
                    >
                      <span className="material-icons">search</span>
                    </button> :
                    <button type="button" className="btn">
                      <span
                        className="material-icons"
                        onClick={() => {
                          closeSearch();
                        }}
                      >
                        close
                      </span>
                    </button>
                  }
                </div>
              </div>
              <div className="filterSearch ms-3">
                <select className="form-control project-filter" 
                onChange={handlePageChange} name="pageChange" id="pageChange"
                value={pageSizeSelect}>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div className="tableCov mt-4 mt-xl-3">
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th onClick={() => handleSort("name")}>
                        Project Name {sortBy === "name" ? (sortOrder === "asc" ? "▼▲" : "▲▼") : "▼▲"}</th>
                      <th onClick={() => handleSort("projectNumber")}>
                        Project Number {sortBy === "projectNumber" ? (sortOrder === "asc" ? "▼▲" : "▲▼") : "▼▲"}</th>
                      <th>Description</th>
                      <th onClick={() => handleSort("projectNumber")}>
                        City {sortBy === "city" ? (sortOrder === "asc" ? "▼▲" : "▲▼") : "▼▲"}</th>
                      <th>Street</th>
                      <th>Zipcode</th>
                      <th>Project Start date</th>
                      <th>Project End date</th>
                      { localStorage.getItem("role") === "Admin" ?
                        <>
                          <th>Active</th>
                          <th>Import tickets</th>
                          <th>Assign </th>
                        </> 
                      : ""}
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={12} className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    </tbody>
                  ) : ProjectList.queryResponse ? (
                    <tbody>
                      {ProjectList.queryResponse.map((item: any) => (
                        <tr key={"Project" + item.projectId}>
                          <td>
                          <Link
                                to={{
                                  pathname:
                                    "/tickets/" + item.projectId,
                                }}
                              >
                                {item.name}
                              </Link>
                          </td>
                          <td>{item.projectNumber}</td>
                          <td>
                            {item.description.length > 20 
                              ? `${item.description.substring(0, 20)}...` 
                              : item.description}</td>
                          <td>{item.city}</td>
                          <td>{item.street}</td>
                          <td>{item.zipcode}</td>
                          <td>
                            {new Date(item.projectStartDate).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td>
                            {new Date(item.projectEndDate).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          { localStorage.getItem("role") === "Admin" ?
                          <>
                            <td>
                              <label className="toggle-switch">
                                <input
                                  type="checkbox"
                                  checked={item.isActive}
                                  onChange={(e) => handleToggle(item.projectId, e.target.checked)} />
                                <span className="slider"></span>
                              </label>
                            </td>
                            <td>
                                <button className="btn btn-sm btn-primary" onClick={() => getProjectFromSystem(item.projectId)}>Get</button>
                            </td>
                            <td>
                              <button className="btn btn-sm btn-info" onClick={() => handleProjectAssignOpen(item.projectId)}>Client</button>
                            </td></> 
                            : ""}
                            <td>
                            <div className="d-inline-flex align-items-center">
                              { localStorage.getItem("role") === "Admin" ?
                                <>
                                  <Link
                                    to={{
                                      pathname: "/project/create-project/" + item.projectId,
                                    }}
                                    className="btn btn-sm btn-success me-1">
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  <button className="btn btn-sm btn-danger me-1" onClick={() => handleDeleteProjectOpen(item.projectId)}>
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </> : 
                                ""
                              }
                              <button className="btn btn-sm btn-info"  onClick={() => handleViewProjectOpen(item)}><i className="fa fa-eye"></i></button>
                            </div>
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={12} className="text-center">
                          No projects available.
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={ProjectList.pageIndex}
                PageSize={ProjectList.pageSize}
                TotalPages={ProjectList.totalPages}
                TotalCount={ProjectList.totalrecords}
                hasNextPage={ProjectList.hasNextPage}
                hasPreviousPage={ProjectList.hasPreviousPage}
              />
            </div>
            {ProjectList && ProjectList.queryResponse.length ?
                <div className="mt-3">
                    {getUserAssignModal()}
                    {getProjectViewDetails()}
                    {deleteProjectModel()}
                </div>
                : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProject;
