import React, { useEffect, useRef } from 'react';
import * as Cesium from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
//import proj4 from 'proj4';
(window as any).CESIUM_BASE_URL = '/cesium/';
interface PlanProps {
    planX: number;
    planY: number;
  }
  
const CesiumViewer: React.FC<PlanProps> = ({planX, planY }) => {
    const cesiumContainer = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        
        if (!cesiumContainer.current) {
            console.error("Cesium container not found.");
            return;
        }

        // Initialize the Cesium Viewer
        const viewer = new Cesium.Viewer(cesiumContainer.current, {
            terrainProvider: new Cesium.CesiumTerrainProvider(),
            baseLayerPicker: true, // Allow switching base maps
        });

        const sourceProjection = '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs'; // Example UTM zone
        const targetProjection = 'EPSG:4326'; // WGS84 (latitude, longitude)

        const planx = 500000; // Example X coordinate in meters
        const plany = 4649776; // Example Y coordinate in meters

        // Convert planx, plany to latitude and longitude using proj4
        // const [longitude, latitude] = proj4(sourceProjection, targetProjection, [planx, plany]);

        // Example Latitude and Longitude
        const latitude =planX;// 32.51590169270833; // Example latitude
        const longitude = planY ;//31.96780090332031; // Example longitude

        // Add a point at the specified location
        viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
            point: {
                pixelSize: 10,
                color: Cesium.Color.RED,
            },
            label: {
                text: `Location: (${latitude.toFixed(6)}, ${longitude.toFixed(6)})`,
                font: '14pt sans-serif',
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(0, -20),
            },
        });

        // Fly to the location
        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, 15000.0), // Adjust height as needed
        });

        return () => {
            viewer.destroy(); // Cleanup viewer on component unmount
        };
    }, []);

    return (
        <div
            ref={cesiumContainer}
            style={{
                width: '100%',
                height: '100vh',
                margin: 0,
                padding: 0,
                overflow: 'hidden',
            }}
        />
    );
};

export default CesiumViewer;
