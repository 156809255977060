import API from "../../config/api";
import { errorMsgs, Success } from "../../context/notify";
import config from "../../config/config";
import { serializeQueryParams } from "../../config/apiutlils";
import { dataActionTypes } from "../constants/ActionTypes";

export const GetTickets = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.Tickets.TicketList
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
};


export const getAllNewTicket = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.Tickets.getAllTicketAsync
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
};

export const GetAlltickets = () => async (dispatch: any) => {
    const path = config.Tickets.GetAlltickets;
    const response = await API("").get(path);
    const data = response?.data?.data || [];
    const totalrecords = data.length;

    dispatch({
        type: dataActionTypes.FETCH_ALL_TICKETS,
        payload: {
            queryResponse: data,
            totalrecords: totalrecords,
        },
    });  
}
export const updateTicketType = (payload: any) => async (dispatch: any) => {
    var path = config.Tickets.UpdateTicketType
    path += serializeQueryParams(payload);
    await API().patch(path).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
}

export const GetAllTicketByProjectId = (proId: any) => async (dispatch: any) => {
    var path = config.Tickets.GetAllTicketByProjectId
    await API().get(path+'?projectId=' +proId).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
}

export const getTicketRequest =(payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.Tickets.getTicketRequest
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
}

export const setTicketApproval = (payload: any,callBack:any) => async (dispatch: any) => {
    var path = config.TicketRequestQuotation.ApproveQuotationRequest
    path += serializeQueryParams(payload);
    const response = await API("", true).post(path);
    if (response.data && callBack) {
        Success(response.data.message);
        callBack(response.data); // Ensure callBack is called only if it exists
    }
}

export const getAllNewTicketetQuotation =(payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.TicketRequestQuotation.GetQuotation
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
}

export const GetTicketStatusByTicketId  = (datas: any, callBack?: any) => async (dispatch: any) => {
    var path = config.Tickets.GetTicketStatusByTicketId;
    const response = await API("", true).get(path +'?ticketId='+ datas.ticketId);
    if (response.data && callBack) {
        callBack(response.data); // Ensure callBack is called only if it exists
    }
}

export const postUserTicketRequest =(payload: any) => async (dispatch: any) => {
    var path = config.TicketRequestQuotation.postUserTicketRequest
    path += serializeQueryParams(payload);
    await API().post(path).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
}

export const UpdateQuotation = (payload: any) => async (dispatch: any) => {
    var path = config.TicketRequestQuotation.UpdateQuotation
    path += serializeQueryParams(payload);
    await API().patch(path).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
}