import { FC, useEffect, useRef, useState } from "react";
import DashboardMenu from "../layouts/dashboardmenu";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination";
import { bool, number } from "yup";
import { PageSize2 } from "../../config/constant";
import {
  getAllNewTicket,
  GetAlltickets,
  postUserTicketRequest,
  UpdateQuotation,
  updateTicketType,
} from "../../redux/actions/ticketAction";
import { Button, Dialog, DialogActions } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { errorMsgs } from "../../context/notify";
import {
  getAllProject,
  UserTicketRequest,
} from "../../redux/actions/ProjectAction";
import {
  GetIssuePriorities,
  GetIssueStatus,
  GetTicketType,
} from "../../redux/actions/dataActions";
import Loader from "../../components/Loader";
import CesiumViewer from "../../components/CesiumViewer";
const NewTicketList: FC = () => {
  const { projectId } = useParams();
  const [selectedProject, setSelectedProject] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { quotationInfo, setquotationInfo } = location.state || {};

  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isActive, setIsActive] = useState(false);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [ticketId, setTicketId] = useState("");
  const [openTicketModal, setOpenTicketSendModal] = useState(false);
  const [openTicketTypeModal, setOpenTicketTypeModal] = useState(false);
  const [openQuotationModal, setOpenQuotationModal] = useState(false);
  const [openViewTicket, setopenViewTicket] = useState(false);
  const [openCesiumView, setOpenCesiumView] = useState(false);
  const [planX, setPlanX] = useState(0);
  const [planY, setPlanY] = useState(0);
  const [ticketViewData, setTicketViewData] = useState(Object);
  const [priorityId, setpriorityId] = useState<number | null>(null);
  const [statusId, setStatusId] = useState<number | null>(null);
  const [ProjectId, setProjectId] = useState<number | null>(null);
  const projectList = useSelector(
    (state: RootState) => state.projectData.projectList
  );
  const prioritiesList = useSelector(
    (state: RootState) => state.accountData.prioritiesList
  );
  const statusList = useSelector(
    (state: RootState) => state.accountData.statusList
  );
  const ticketTypeList = useSelector(
    (state: RootState) => state.accountData.ticketType
  );
  const allTicketList = useSelector(
    (state: RootState) => state.accountData.allTicketList
  );
  const [isLoading, setIsLoading] = useState(true);
  const [ticketType, setTicketType] = useState("");
  const didRun = useRef(false);
  const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalRecord, setTotalRecord] = useState<number>();
  const [isTotalRecordFetched, setIsTotalRecordFetched] = useState(false);
  const [pageSizeSelect, setPageSizeSelect] = useState(PageSize2);

  const [ticketList, SetTicketList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const getProjectList = () => {
    setIsLoading(true);
    if (didRun.current) return; // Skip if already executed
    didRun.current = true; // Mark as executed
    dispatch(getAllProject()); // Dispatch the action to fetch projects
    dispatch(GetIssuePriorities());
    dispatch(GetIssueStatus());
    dispatch(GetTicketType());
    if (quotationInfo) {
      const ticketIds = quotationInfo.ticketResquetModels.map(
        (ticket: any) => ticket.ticketId
      );
      setSelectedTickets(ticketIds); // Set state with actual ticketIds (numbers)
    } else {
      setSelectedTickets([]);
    }
  };

  useEffect(() => {
    getProjectList(); // Fetch project list only once when the component mounts
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    searchTickets();
    if (CurrentPage === 1 && !isTotalRecordFetched) {
      searchTicketsWithTotalRecord();
      setIsTotalRecordFetched(true);
    }
  }, [CurrentPage, searchQuery, pageSizeSelect]);

  const searchTickets = (
    column: string = sortBy,
    order: string = sortOrder,
    priority: number | null = priorityId,
    status: number | null = statusId,
    projectId: number | null = ProjectId
  ) => {
    const payload: Record<string, any> = {
      PageNumber: CurrentPage,
      PageSize: pageSizeSelect,
      Search: isSearch ? "" : searchText,
      SortColumn: column,
      SortOrder: order,
    };

    if (priority) {
      payload.IssuePriorityId = priority;
    }
    if (status) {
      payload.IssueStatusId = status;
    }
    if (projectId) {
      payload.ProjectId = projectId;
    }
    try {
      dispatch(
        getAllNewTicket(payload, (response: any) => {
          SetTicketList(response.data);
          setTotalRecord(response.data.totalrecords);
        })
      );
    } catch (error) {
      console.error("Error fetching tickets:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  const searchTicketsWithTotalRecord = () => {
    dispatch(GetAlltickets());
  };

  const closeSearch = () => {
    SetCurrentPage(1);
    setSearchText("");
    setSearchQuery("");
    setIsSearch(false); // Exit search mode
    searchTickets();
  };

  const handleSort = (column: string) => {
    const order = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(order);
    searchTickets(column, order);
  };

  const handleTicketSendOpen = (id: string) => {
    setOpenTicketSendModal(true);
    if (id) setTicketId(id);
  };

  const handleViewAssignTicketType = (ticketId: any, ttype: any) => {
    setOpenTicketTypeModal(true);
    if (ticketId) setTicketId(ticketId);
    const typeid =
      ticketTypeList.data.find((typeData: any) => typeData.type_name === ttype)
        ?.ticketTypeId || "";
    if (ttype) setTicketType(typeid);
  };

  const handleClose = () => {
    setOpenTicketSendModal(false);
    setOpenQuotationModal(false);
    setOpenTicketTypeModal(false);
    setOpenCesiumView(false);
  };

  const handleAfterSubmit = (resetForm?: any) => {
    resetForm();
    setOpenTicketSendModal(false);
    setOpenQuotationModal(false);
  };

  const getTicketSendRquest = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openTicketModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-4">Send Quotation</h4>

              <div className="usergroup-container">
                <div className="mt-3">
                  <Formik
                    initialValues={{
                      Description: "",
                      quotation: "",
                      ticketId: ticketId,
                      userId: parseInt(
                        localStorage.getItem("userId") || "0",
                        10
                      ),
                    }}
                    validationSchema={Yup.object({
                      Description: Yup.string().required(
                        "Please Enter Description"
                      ),
                      quotation: Yup.string().required(
                        "Please Enter quotation"
                      ),
                    })}
                    onSubmit={(initialValues) => {
                      dispatch(postUserTicketRequest(initialValues));
                      setOpenTicketSendModal(false);
                      handleQuotationClose();
                    }}
                  >
                    {({ values, handleChange, errors, touched, resetForm }) => (
                      <Form>
                        <div className="group-form mb-2">
                          <label htmlFor="group_name" className="group-label">
                            Description
                          </label>
                          <textarea
                            id="Description"
                            name="Description"
                            placeholder="Enter Description"
                            className="info-create-group form-control mt-2"
                            rows={5}
                            onChange={handleChange}
                            value={values.Description}
                          ></textarea>
                          <>
                            {touched.Description && errors.Description
                              ? errorMsgs(errors.Description)
                              : null}
                          </>
                        </div>
                        <div className="group-form">
                          <label htmlFor="group_name" className="group-label">
                            Quotation
                          </label>
                          <input
                            id="quotation"
                            name="quotation"
                            type="text"
                            placeholder="Enter quotation"
                            className="info-create-group form-control mt-2"
                            onChange={handleChange}
                            value={values.quotation}
                          ></input>
                          <>
                            {touched.quotation && errors.quotation
                              ? errorMsgs(errors.quotation)
                              : null}
                          </>
                        </div>
                        <DialogActions>
                          <div className="float-end">
                            <Button
                              type="button"
                              className="close-button btn-warning"
                              autoFocus
                              onClick={() => handleAfterSubmit(resetForm)}
                            >
                              Close
                            </Button>
                            <Button
                              className="save-button btn btn-primary ms-3"
                              type="submit"
                            >
                              Send
                            </Button>
                          </div>
                        </DialogActions>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleViewTicketOpen = (datas: any) => {
    setTicketViewData(datas);
    setopenViewTicket(true);
  };

  const getTicketViewDetails = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openViewTicket}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3 ticket-details">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-3">View Ticket Details</h4>
              <p>
                <b>Ticket Number : </b>
                {ticketViewData.ticketNumber}
              </p>
              <p>
                <b>Subject : </b>
                {ticketViewData.subject}
              </p>
              <p>
                <b>Priority : </b> {ticketViewData.priorityName}
              </p>
              <p>
                <b>Status : </b> {ticketViewData.statusName}
              </p>
              <p>
                <b>Ticket Type : </b> {ticketViewData.ticketTypeName}
              </p>
              <p>
                <b>Create Date : </b>{" "}
                {ticketViewData.createdAt
                  ? new Date(ticketViewData.createdAt).toLocaleDateString(
                      "en-GB"
                    )
                  : ""}
              </p>
              <p>
                <b>Extension Date : </b>{" "}
                {ticketViewData.extensionDate
                  ? new Date(ticketViewData.extensionDate).toLocaleDateString(
                      "en-GB"
                    )
                  : ""}{" "}
              </p>
              <p>
                <b>Due Date : </b>{" "}
                {ticketViewData.dueDate
                  ? new Date(ticketViewData.dueDate).toLocaleDateString("en-GB")
                  : ""}{" "}
              </p>
              <DialogActions>
                <div className="float-end">
                  <Button
                    type="button"
                    className="close-button btn-warning"
                    autoFocus
                    onClick={() => setopenViewTicket(false)}
                  >
                    Close
                  </Button>
                </div>
              </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleProjectChange = (event: any) => {
    setProjectId(event.target.value);
    searchTickets();
  };

  const handleProjectPriority = (event: any) => {
    const selectedPriorityId = event.target.value;
    SetCurrentPage(1);
    setpriorityId(selectedPriorityId); // Update priorityId state
    searchTickets(undefined, undefined, selectedPriorityId, statusId); // Pass the updated priorityId
  };

  const handleProjectStatus = (event: any) => {
    const selectedStatusId = event.target.value;
    SetCurrentPage(1);
    setStatusId(selectedStatusId); // Update statusId state
    searchTickets(undefined, undefined, priorityId, selectedStatusId); // Pass the updated statusId
  };

  // Handle checkbox change
  const handleCheckboxChange = (ticketId: any) => {
    setErrorMessage(""); // Clear error message
    const parsedTicketId = parseInt(ticketId, 10);
    setSelectedTickets((prevSelectedTickets) => {
      if (prevSelectedTickets.includes(parsedTicketId)) {
        return prevSelectedTickets.filter(
          (ticketId) => ticketId !== parsedTicketId
        );
      } else {
        return [...prevSelectedTickets, parsedTicketId];
      }
    });
  };

  // Validate and open modal
  const handleSendQuotationClick = () => {
    if (selectedTickets.length === 0) {
      setErrorMessage("At least one checkbox is required.");
      return;
    }
    setErrorMessage(""); // Clear error message
    setOpenQuotationModal(true);
  };

  const handleCancelTicket = (ticketId: number) => {
    console.log(ticketId);

    setSelectedTickets((selectedTickets) =>
      selectedTickets.filter((id) => id !== ticketId)
    );

    console.log(selectedTickets);
  };

  const handleQuotationClose = () => {
    setSelectedTickets([]);
    setOpenQuotationModal(false);
    setOpenTicketSendModal(false);
    navigate(`/quotation`);
  };

  const getMultipleQuotationSend = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openQuotationModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-4">Send Quotation</h4>
              {/* Display Selected Tickets */}
              <div className="selected-tickets-list mb-3">
                <h5>Selected Tickets:</h5>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Ticket No</th>
                      <th>Subject</th>
                      <th>Priority</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allTicketList.data
                      .filter((titem: any) =>
                        selectedTickets.includes(titem.ticketId)
                      )
                      .map((titem: any, index: number) => (
                        <tr key={titem.ticketId}>
                          <td>{index + 1}</td>
                          <td>{titem.ticketNumber}</td>
                          <td>
                            {titem.subject.length > 50
                              ? `${titem.subject.substring(0, 50)}...`
                              : titem.subject}
                          </td>
                          <td>{titem.priorityName}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleCancelTicket(titem.ticketId)}
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="usergroup-container">
                <div className="mt-3">
                  {quotationInfo ? (
                    <Formik
                      initialValues={{
                        QuotationId: quotationInfo.quotationId,
                        Description: quotationInfo.description,
                        quotation: quotationInfo.quotation,
                        ticketId: selectedTickets,
                        userid: parseInt(
                          localStorage.getItem("userId") || "0",
                          10
                        ),
                      }}
                      validationSchema={Yup.object({
                        Description: Yup.string().required(
                          "Please Enter Description"
                        ),
                        quotation: Yup.string().required(
                          "Please Enter quotation"
                        ),
                      })}
                      onSubmit={(values) => {
                        const formattedValues = {
                          ...values,
                          ticketId: values.ticketId.join(","), // Convert ticketId array to comma-separated string
                        };
                        console.log(formattedValues);
                        dispatch(UpdateQuotation(formattedValues));
                        handleQuotationClose();
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        errors,
                        touched,
                        resetForm,
                      }) => (
                        <Form>
                          <div className="group-form mb-2">
                            <label htmlFor="group_name" className="group-label">
                              Description
                            </label>
                            <textarea
                              id="Description"
                              name="Description"
                              placeholder="Enter Description"
                              className="info-create-group form-control mt-2"
                              rows={5}
                              onChange={handleChange}
                              value={values.Description}
                            ></textarea>
                            <>
                              {touched.Description && errors.Description
                                ? errorMsgs(errors.Description)
                                : null}
                            </>
                          </div>
                          <div className="group-form">
                            <label htmlFor="group_name" className="group-label">
                              Quotation
                            </label>
                            <input
                              id="quotation"
                              name="quotation"
                              type="text"
                              placeholder="Enter quotation"
                              className="info-create-group form-control mt-2"
                              onChange={handleChange}
                              value={values.quotation}
                            ></input>
                            <>
                              {touched.quotation && errors.quotation
                                ? errorMsgs(errors.quotation)
                                : null}
                            </>
                          </div>
                          <DialogActions>
                            <div className="float-end">
                              <Button
                                type="button"
                                className="close-button btn-warning"
                                autoFocus
                                onClick={() => handleAfterSubmit(resetForm)}
                              >
                                Close
                              </Button>
                              <Button
                                className="save-button btn btn-primary ms-3"
                                type="submit"
                              >
                                Update
                              </Button>
                            </div>
                          </DialogActions>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <Formik
                      initialValues={{
                        description: "",
                        quotation: "",
                        ticketId: selectedTickets,
                        userId: parseInt(
                          localStorage.getItem("userId") || "0",
                          10
                        ),
                      }}
                      validationSchema={Yup.object({
                        description: Yup.string().required(
                          "Please Enter Description"
                        ),
                        quotation: Yup.string().required(
                          "Please Enter quotation"
                        ),
                      })}
                      onSubmit={(values) => {
                        const formattedValues = {
                          ...values,
                          ticketId: values.ticketId.join(","), // Convert ticketId array to comma-separated string
                        };
                        dispatch(postUserTicketRequest(formattedValues));
                        handleQuotationClose();
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        errors,
                        touched,
                        resetForm,
                      }) => (
                        <Form>
                          <div className="group-form mb-2">
                            <label htmlFor="group_name" className="group-label">
                              Description
                            </label>
                            <textarea
                              id="description"
                              name="description"
                              placeholder="Enter description"
                              className="info-create-group form-control mt-2"
                              rows={5}
                              onChange={handleChange}
                              value={values.description}
                            ></textarea>
                            <>
                              {touched.description && errors.description
                                ? errorMsgs(errors.description)
                                : null}
                            </>
                          </div>
                          <div className="group-form">
                            <label htmlFor="group_name" className="group-label">
                              Quotation
                            </label>
                            <input
                              id="quotation"
                              name="quotation"
                              type="text"
                              placeholder="Enter quotation"
                              className="info-create-group form-control mt-2"
                              onChange={handleChange}
                              value={values.quotation}
                            ></input>
                            <>
                              {touched.quotation && errors.quotation
                                ? errorMsgs(errors.quotation)
                                : null}
                            </>
                          </div>
                          <DialogActions>
                            <div className="float-end">
                              <Button
                                type="button"
                                className="close-button btn-warning"
                                autoFocus
                                onClick={() => handleAfterSubmit(resetForm)}
                              >
                                Close
                              </Button>
                              <Button
                                className="save-button btn btn-primary ms-3"
                                type="submit"
                              >
                                Send
                              </Button>
                            </div>
                          </DialogActions>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };
  const getCesiumView = () => {
    return (
      <Dialog
        fullWidth={true}
        //  maxWidth="sm"
        open={openCesiumView}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <CesiumViewer planX={planX} planY={planY} />
      </Dialog>
    );
  };
  const getTicketTypeUpdate = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openTicketTypeModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-4">Assign Ticket Type</h4>

              <div className="usergroup-container">
                <div className="mt-3">
                  <Formik
                    initialValues={{
                      TicketType: "",
                      Ticket: ticketId,
                    }}
                    validationSchema={Yup.object({
                      TicketType: Yup.string().required(
                        "Please Select at leate one Type"
                      ),
                    })}
                    onSubmit={async (initialValues) => {
                      await dispatch(updateTicketType(initialValues));
                      await searchTickets();
                      handleClose();
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <div className="group-form mb-2">
                          <label
                            htmlFor="group_name"
                            className="group-label mb-1"
                          >
                            Ticket Type
                          </label>
                          <select
                            className="form-control project-filter"
                            name="TicketType"
                            id="TicketType"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setFieldValue("TicketType", selectedValue); // Update Formik field value
                              setTicketType(selectedValue); // Optionally update external state if needed
                            }}
                            value={ticketType}
                          >
                            <option>Select Ticket Type</option>
                            {ticketTypeList.data.map((typeData: any) => (
                              <option
                                key={typeData.ticketTypeId}
                                value={typeData.ticketTypeId}
                              >
                                {typeData.type_name}
                              </option>
                            ))}
                          </select>
                          <>
                            {touched.TicketType && errors.TicketType
                              ? errorMsgs(errors.TicketType)
                              : null}
                          </>
                        </div>

                        <DialogActions>
                          <div className="float-end">
                            <Button
                              type="button"
                              className="close-button btn-warning"
                              autoFocus
                              onClick={() => handleClose()}
                            >
                              Close
                            </Button>
                            <Button
                              className="save-button btn btn-primary ms-3"
                              type="submit"
                            >
                              Send
                            </Button>
                          </div>
                        </DialogActions>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handlePageChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10);
    setPageSizeSelect(selectedPageSize); // Update page size
    SetCurrentPage(1); // Reset to the first page
  };
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Tickets</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4">
                <NavLink to="/quotation" className="btn btn-md btn-success">
                  See All Quotation
                </NavLink>
              </div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-1 pb-4 pt-xl-1 pb-xl-5">
            <div className="filterCon d-flex flex-wrap align-items-center mt-1 mt-xl-2">
              <div className="filterBox filterSearchBox">
                <div className="filterSearch">
                  <input
                    type="text"
                    onChange={(event) => setSearchText(event.target.value)}
                    className="input"
                    placeholder="Search By Subject..."
                  />
                  {!isSearch ? (
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        SetCurrentPage(1);
                        setIsSearch(true);
                        searchTickets();
                      }}
                    >
                      <span className="material-icons">search</span>
                    </button>
                  ) : (
                    <button type="button" className="btn">
                      <span
                        className="material-icons"
                        onClick={() => {
                          closeSearch();
                        }}
                      >
                        close
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="filterSearch">
                <select
                  className="form-control project-filter"
                  onChange={handleProjectChange}
                  name="seacrhByProject"
                  id="seacrhByProject"
                  value={selectedProject}
                >
                  <option>Search Project</option>
                  {projectList.data.map((projectData: any) => (
                    <option
                      key={projectData.projectId}
                      value={projectData.projectId}
                    >
                      {projectData.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handleProjectPriority}
                  name="seacrhByProjectStatus"
                  id="seacrhByProjectStatus"
                >
                  <option value={0}>Ticket Priority</option>
                  {prioritiesList.data.map((priorityData: any) => (
                    <option
                      key={priorityData.issuePrioritiesId}
                      value={priorityData.issuePrioritiesId}
                    >
                      {priorityData.priority_Level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handleProjectStatus}
                  name="seacrhByProjectStatus"
                  id="seacrhByProjectStatus"
                >
                  <option value={0}>Ticket Status</option>
                  {statusList.data.map((statusData: any) => (
                    <option
                      key={statusData.issueStatusId}
                      value={statusData.issueStatusId}
                    >
                      {statusData.status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handlePageChange}
                  name="pageChange"
                  id="pageChange"
                  value={pageSizeSelect}
                >
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
              {localStorage.getItem("role") != "Admin" ? (
                <div className="filterSearch ms-3">
                  <div>
                    <button
                      className="btn-quotation"
                      onClick={() => handleSendQuotationClick()}
                    >
                      Send Quotation
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="tableCov mt-4 mt-xl-3">
              {errorMessage && (
                <div className="text-danger mb-3">{errorMessage}</div>
              )}
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      {localStorage.getItem("role") != "Admin" ? <th></th> : ""}
                      <th>Sr No</th>
                      <th onClick={() => handleSort("subject")}>
                        Subject{" "}
                        {sortBy === "subject"
                          ? sortOrder === "asc"
                            ? "▼▲"
                            : "▲▼"
                          : "▼▲"}
                      </th>
                      <th onClick={() => handleSort("priorityName")}>
                        Priority{" "}
                        {sortBy === "priorityName"
                          ? sortOrder === "asc"
                            ? "▼▲"
                            : "▲▼"
                          : "▼▲"}
                      </th>
                      <th>Ticket Status</th>
                      <th>Ticket Type</th>
                      <th>
                        {localStorage.getItem("role") === "Admin"
                          ? ""
                          : "Request"}
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td
                          colSpan={
                            localStorage.getItem("role") === "Admin" ? 9 : 9
                          }
                          className="text-center"
                        >
                          <Loader />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ticketList.queryResponse && (
                      <tbody>
                        {ticketList.queryResponse.map(
                          (item: any, index: any) => (
                            <tr key={"Ticket" + item.ticketId}>
                              {localStorage.getItem("role") != "Admin" ? (
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      name="ticketId"
                                      value={item.ticketId}
                                      checked={selectedTickets.includes(
                                        item.ticketId
                                      )} // Retain selection
                                      onChange={() =>
                                        handleCheckboxChange(
                                          Number(item.ticketId)
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`user_${item.ticketId}`}
                                    ></label>
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                {typeof ticketList.pageIndex === "number" &&
                                typeof ticketList.pageSize === "number"
                                  ? (ticketList.pageIndex - 1) *
                                      ticketList.pageSize +
                                    index +
                                    1
                                  : index + 11}
                              </td>
                              <td
                                onClick={() => {
                                  setOpenCesiumView(true);
                                  setPlanY(item.planY);
                                  setPlanX(item.planX);
                                }}
                              >
                                {item.subject.length > 30
                                  ? `${item.subject.substring(0, 30)}...`
                                  : item.subject}
                              </td>
                              <td>{item.priorityName}</td>
                              <td>{item.statusName}</td>
                              <td>
                                {item.ticketTypeName ? (
                                  <button
                                    className="btn btn-sm btn-info"
                                    onClick={() =>
                                      handleViewAssignTicketType(
                                        item.ticketId,
                                        item.ticketTypeName
                                      )
                                    }
                                  >
                                    {" "}
                                    {item.ticketTypeName}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-warning"
                                    onClick={() =>
                                      handleViewAssignTicketType(
                                        item.ticketId,
                                        ""
                                      )
                                    }
                                  >
                                    Assign
                                  </button>
                                )}
                              </td>
                              <td>
                                {localStorage.getItem("role") != "Admin" ? (
                                  item.assignStatus === "Requested" ? (
                                    ""
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() =>
                                        handleTicketSendOpen(item.ticketId)
                                      }
                                    >
                                      Send Quotation
                                    </button>
                                  )
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-info"
                                  onClick={() => handleViewTicketOpen(item)}
                                >
                                  <i className="fa fa-eye"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    )
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={ticketList.pageIndex}
                PageSize={ticketList.pageSize}
                TotalPages={ticketList.totalPages}
                TotalCount={ticketList.totalrecords}
                hasNextPage={ticketList.hasNextPage}
                hasPreviousPage={ticketList.hasPreviousPage}
              />
            </div>
          </div>
          {ticketList && ticketList.queryResponse.length ? (
            <div className="mt-3">
              {getTicketSendRquest()}
              {getTicketViewDetails()}
              {getMultipleQuotationSend()}
              {getTicketTypeUpdate()}
              {getCesiumView()}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default NewTicketList;
