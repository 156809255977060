import API from "../../config/api";
import config from "../../config/config";
import { dataActionTypes } from "../constants/ActionTypes";

export const fetchUserType = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    await API().get('/api/Masters/GetUserType', defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}

export const GetIssuePriorities = () => async (dispatch: any) => {
    const path = config.Master.GetIssuePriorities;
    const response = await API("").get(path);
    const data = response?.data?.data || [];
    const totalrecords = data.length;

    dispatch({
        type: dataActionTypes.FETCH_TICKET_PRIORITIES,
        payload: {
            queryResponse: data,
            totalrecords: totalrecords,
        },
    });  
}

export const GetChartsData = () => async (dispatch: any) => {
    const path = config.KPI.GetChartsData;
    const response = await API("").get(path);
    const data = response;

    dispatch({
        type: dataActionTypes.FEATCH_KPI_DATA,
        payload: {
            queryResponse: data
        },
    });  
}

export const GetIssueStatus = () => async (dispatch: any) => {
    const path = config.Master.GetIssueStatus;
    const response = await API("").get(path);
    const data = response?.data?.data || [];
    const totalrecords = data.length;

    dispatch({
        type: dataActionTypes.FETCH_TICKET_STATUS,
        payload: {
            queryResponse: data,
            totalrecords: totalrecords,
        },
    });  
}

export const GetTicketType = () => async (dispatch: any) => {
    const path = config.Master.GetTicketType;
    const response = await API("").get(path);
    const data = response?.data?.data || [];
    const totalrecords = data.length;

    dispatch({
        type: dataActionTypes.FETCH_TICKET_TYPE,
        payload: {
            queryResponse: data,
            totalrecords: totalrecords,
        },
    });  
}

export const fetchUsersList = (handleNext?: any) => async (dispatch: any) => {
    var path = "/api/Client/Getallusers"
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const fatchClientOverview = (handleNext?: any) => async (dispatch: any) => {
    var path = "/api/KPI/GetClientOverview"
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};