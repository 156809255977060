import { FC, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import * as Yup from "yup";
import { useFormik, FormikProps } from "formik";

import TopImg from "../../assets/img/image1.png";
import Logo from "../../assets/img/logo.png";
import BotImg from "../../assets/img/image2.png";
import Lock from "../../assets/img/lock.png";

import { formFields } from "../../components/messages";
import {
  resetPasswordInfo,
  forgotPasswordInfo,
} from "../../Typings/accountListing";
import { errorMsgs, handleError, Success } from "../../context/notify";
import { userResetPassword } from "../../redux/actions/accountAction";

const ResetPassword: FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  const formik: FormikProps<resetPasswordInfo> = useFormik({
    initialValues: {
      userId: Number(localStorage.getItem("userId")) || 0,
      password: "",
      confirmPassword: "",
    },
    

    validationSchema : Yup.object({
      password: Yup.string()
        .required("New password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setIsShowLoader(true);
      dispatch(
        userResetPassword(values, (response: any) => {
          try {
            formik.resetForm();
            if(response.statusCode == 1) {
              Success("Password successfully reset!")
              // Store user data and navigate to the verify page
              localStorage.setItem("userId", response.data.userId);
              localStorage.setItem("accessToken", response.data.accessToken);
              localStorage.setItem("refreshToken", response.data.refreshToken);
              // Uncomment if additional user info needs to be stored
              // localStorage.setItem('userInfo', JSON.stringify(response.data));
              navigate("/dashboard");
              console.log("Response:", response); // Debugging information
            }else{
              errorMsgs(response.message); 
            }
          } catch (error) {

          } finally {
            setSubmitting(false);
            setIsShowLoader(false);
          }
        })
      );
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleDataSubmit = (response: any) => {
    console.log(response);
    formik.resetForm();
    if (!response || typeof response.status === "undefined") {
      // Handle unexpected response format
      errorMsgs("Unexpected error occurred. Please try again.");
      return;
    }
    if (response.data.statusCode == 0) {
      // Handle unexpected response format
      errorMsgs(response.data.message);
      return;
    }
    if (response.statusCode == 0) {
      errorMsgs(response.message); // Show error message
    } else {
      // Store user data and navigate to the verify page
      localStorage.setItem("userId", response.data.data.userId);
      localStorage.setItem("accessToken", response.data.data.accessToken);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
      // Uncomment if additional user info needs to be stored
      // localStorage.setItem('userInfo', JSON.stringify(response.data));
      navigate("/dashboard");
    }
  };

  return (
    <div className="loginBg d-flex align-items-center justify-content-center">
      <div className="loginInner d-flex align-items-center justify-content-between">
        <div className="left">
          <div className="topImg">
            <img alt="Top decoration" src={TopImg} />
          </div>
          <div className="logo d-flex justify-content-center">
            <img alt="Logo" src={Logo} />
          </div>
          <div className="botImg d-flex justify-content-end">
            <img alt="Bottom decoration" src={BotImg} />
          </div>
        </div>
        <div className="lgoinForm">
          <div className="loginHd exoFont text-center pb-3 ">
            Reset Password!
          </div>
          <div className=" exoFont text-center pb-3 pb-sm-5">
            Please enter your password and confirm password.
          </div>
          <form onSubmit={formik.handleSubmit}>
            <ul className="loginForm">
              <li>
                <div className="position-relative">
                  <img className="icon" alt="Lock icon" src={Lock} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="loginInput password"
                    {...formik.getFieldProps("password")}
                  />
                  <span
                    className="material-icons-outlined eye"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? "visibility" : "visibility_off"}
                  </span>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>
              </li>
              <li>
                <div className="position-relative">
                  <img className="icon" alt="Lock icon" src={Lock} />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="loginInput password"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  <span
                    className="material-icons-outlined eye"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? "visibility" : "visibility_off"}
                  </span>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                    <div className="error">{formik.errors.confirmPassword}</div>
                  ) : null}
                </div>
              </li>
              <li className="pt-4">
                <button
                  type="submit"
                  className="signinBtn position-relative"
                  disabled={!formik.isValid || formik.isSubmitting}
                >{isShowLoader ? (
                  <div className="loader"></div>
                ) : (
                  "Reset Password"
                )}

                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
