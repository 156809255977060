import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo.png';
import Dashboard from '../../assets/img/dashboard.png';
import Clients from '../../assets/img/clients.png';
import Projects from '../../assets/img/projects.png';
import Tickets from '../../assets/img/tickets.png';
import Reports from '../../assets/img/reports.png';
import Logout from '../../assets/img/logout.png';

const DashboardMenu: FC = () => {

    const [subActive, setSubActive] = useState(false);
    const navigate = useNavigate();
    const showSubmenu = () => {
        setSubActive(!subActive);
    };
    return (
       <>
           <div className="dbLeft">
                <div className="dbLogo"><img src={Logo} alt="" /></div>
                <div className="dbMenu">
                    <ul>
                        <li><NavLink  to="/dashboard"><img src={Dashboard} alt="" /> Dashboard</NavLink></li>
                        <li><NavLink  to="/client"><img src={Clients} alt="" /> Clients</NavLink></li>
                        <li><NavLink  to="/project"><img src={Projects} alt="" /> Projects</NavLink></li>
                        <li><a href="#"><img src={Tickets} alt="" /> Tickets</a></li>
                        <li>
                            <a onClick={showSubmenu} className={subActive ? 'active' : ''}><img src={Reports} alt="" /> Reports <span className="material-icons arrow">expand_more</span></a>
                            <ul className={subActive ? 'subLink active' : 'subLink'}>
                                <li><a href="#">Activity Log</a></li>
                                <li><a href="#">My Profile</a></li>
                                <li><a href="#">Change Password</a></li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li className="logout"><a href="#" onClick={()=> navigate("/")}><img src={Logout} alt="" /> Logout</a></li>
                    </ul>
                </div>
           </div>
       </>
    );
}

export default DashboardMenu;