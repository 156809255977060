import { FC, useState, useRef } from "react";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";

const Dashboard: FC = () => {
    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
      setIsActive(!isActive);
    };
 
    return ( 
        <>
            <div className={isActive ? 'dbCon active' : 'dbCon'}>
                <DashboardMenu />
                <div className="dbRight">
                    <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
                        <div className="dbTopHd">Dashboard</div>
                        <div className="d-flex align-items-center justify-content-between hraderRgt">
                            <DashboardHeader onClick={toggleMenu} />
                        </div>
                    </header>
                </div>
            </div>
        </>
    );
}
export default Dashboard;