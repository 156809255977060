import API from "../../config/api";
import { Success } from "../../context/notify";
import { accountActionTypes } from "../constants/ActionTypes";
import config from "../../config/config";
import { serializeQueryParams } from "../../config/apiutlils";

export const GetProjects = (payload: any, handleNext?: any) => async (dispatch: any) => {
    // console.log("callll");
    var path = config.Project.ProjectList
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const GetprojectDetails = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.Project.GetProjectDetails
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const AddProjectDetails = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = config.Project.AddUpdateProject
    // path += serializeQueryParams(defaultparams);
    await API().post(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}

