export const dataActionTypes = {
    FATCH_USERTYPE_LIST: "FATCH_USERTYPE_LIST",
    FETCH_TICKET_PRIORITIES:"FETCH_TICKET_PRIORITIES",
    FETCH_TICKET_STATUS:"FETCH_TICKET_STATUS",
    FETCH_TICKET_TYPE:"FETCH_TICKET_TYPE",
    FEATCH_KPI_DATA:"FEATCH_KPI_DATA",
    FETCH_PARENT_PROJECT:"FETCH_PARENT_PROJECT",
    FETCH_ALL_TICKETS:"FETCH_ALL_TICKETS",
    FETCH_PROJECT_USER_ID:"FETCH_PROJECT_USER_ID"
}

export const accountActionTypes = {
    FATCH_LOGIN: "FATCH_LOGIN",
    FATCH_REGISTER: "FATCH_REGISTER",
    FATCH_CHANGE_PASSWORD: "FATCH_CHANGE_PASSWORD",
    GET_PROFILE_INFO: "GET_PROFILE_INFO",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    USER_RESET_PASSWORD_LINK: "USER_RESET_PASSWORD_LINK",
    GET_LOGOUT: "GET_LOGOUT",
    VERIFY_OTP: "VERIFY_OTP",
    FETCH_NOTIFICATION_LIST: "FETCH_NOTIFICATION_LIST"
}

export const projectActionTypes = {
    FETCH_PROJECT_LIST: "FETCH_PROJECT_LIST",
}

export const clientActionTypes = {
    FATCH_EMAIL_EXIST: "FATCH_EMAIL_EXIST",
    FETCH_CLIENT_LIST: "FETCH_CLIENT_LIST",
    FETCH_ALL_CLIENT:"FETCH_ALL_CLIENT"
}