import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers/index";
import {thunk} from "redux-thunk";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const initialState = {};
const middleware = [thunk];
const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middleware))
    
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;