/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState  } from "react";
import Header from "./layouts/header";
import Footer from "./layouts/footer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Client1 from "../assets/img/axis.png";
import Client2 from "../assets/img/integral.png";
import Client3 from "../assets/img/equans.png";
import Client4 from "../assets/img/cbre.png";
import Client5 from "../assets/img/cushman.png";
import Client6 from "../assets/img/asq.png";
import IntroductionImg from "../assets/img/introduction-img.jpg";
import ProjectImg1 from "../assets/img/project-img1.jpg";
import ProjectImg2 from "../assets/img/project-img2.jpg";
import ProjectImg3 from "../assets/img/project-img3.jpg";
import OfferIcon1 from "../assets/img/offer-icon1.png";
import OfferIcon2 from "../assets/img/offer-icon2.png";
import OfferIcon3 from "../assets/img/offer-icon3.png";
import OfferIcon4 from "../assets/img/offer-icon4.png";
import contactBanner from "../assets/img/contact-banner-img.png";
import maintenanceImg from "../assets/img/maintenance-programme.jpg";
import InspectionsImg1 from "../assets/img/inspections-img1.jpg";
import InspectionsImg2 from "../assets/img/inspections-img2.jpg";
import InspectionsImg3 from "../assets/img/inspections-img3.jpg";
import InspectionsImg4 from "../assets/img/inspections-img4.jpg";
import InspectionsImg5 from "../assets/img/inspections-img5.jpg";

import UserImg1 from "../assets/img/user-img1.jpg";
import UserImg2 from "../assets/img/user-img2.jpg";
import UserImg3 from "../assets/img/user-img3.jpg";
import UserImg4 from "../assets/img/user-img4.jpg";
import UserImg5 from "../assets/img/user-img5.jpg";
import Slider from "react-slick";

const Index: FC = () => {
  var projectSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
          breakpoint: 999,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
          }
      },
      {
          breakpoint: 599,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
          }
      },
  ]
  };
  var inspectionsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
          breakpoint: 999,
          settings: {
              slidesToShow: 3,
          }
      },
      {
          breakpoint: 599,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
          }
      },
  ]
  };

  const sliderForRef = useRef<Slider | null>(null);
  const sliderNavRef = useRef<Slider | null>(null);
  const [nav1, setNav1] = useState<Slider | undefined>(undefined);
  const [nav2, setNav2] = useState<Slider | undefined>(undefined);
  useEffect(() => {
    if (sliderForRef.current && sliderNavRef.current) {
      setNav1(sliderForRef.current);
      setNav2(sliderNavRef.current);
    }
  }, []);

  const settingsFor = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
    arrow:false,
  };

  const settingsNav = {
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: nav1,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
    arrow:true,
    responsive: [
      {
          breakpoint: 999,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 599,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
          }
      },
  ]
    
  };
  
  return (
    <>
      <div>
        <Header />
        <section className="bannerCon">
          <div className="container d-flex align-items-center justify-content-center">
            <div className="bannerInfo">
              <div className="bannerHd text-center mb-2">
                <span>Façade Inspection Specialists</span>
              </div>
              <div className="bannerText text-center pb-5">
                Inspecting and maintaining Canary Wharf's skyline since 1992.
              </div>
              {/* <div className="text-center">
                <a href="#" className="contactBtn">
                  CONTACT NOW
                </a>
              </div> */}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="clientBox p-sm-4 p-2">
              <ul className="d-flex flex-wrap text-center align-items-center justify-content-around">
                <li>
                  <img alt="" src={Client1} />
                </li>
                <li>
                  <img alt="" src={Client2} />
                </li>
                <li>
                  <img alt="" src={Client3} />
                </li>
                <li>
                  <img alt="" src={Client4} />
                </li>
                <li>
                  <img alt="" src={Client5} />
                </li>
                <li>
                  <img alt="" src={Client6} />
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center aboutBox">
              <div className="left">
                <div className="img">
                  <img alt="About Us" src={IntroductionImg} />
                </div>
                <div className="over d-flex align-items-center justify-content-center">
                  <div className="count">30.</div>
                  <div className="info">
                    <span className="d-flex">Years of INdustry</span>
                    experience
                  </div>
                </div>
              </div>
              <div className="right ps-sm-4 ps-0">
                <div className="mainHd pb-4">
                  <span className="d-block pb-3">About Us</span> Introduction
                </div>
                <div className="textBox pb-sm-5 pb-4">
                  With over 30-years of experience in the Canary Wharf and
                  London area, Turnstone Construction is an innovative façade
                  inspection & maintenance provider. Our team of skilled
                  professionals employs the latest technology and techniques to
                  deliver superior service quality, ensuring safety,
                  sustainability, and cost-efficiency for all our clients.
                </div>
                <div>
                  <a href="#" className="readMore d-flex align-items-center">
                    <span className="material-icons">arrow_right_alt</span> Read
                    more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-5 pb-5 projectCon">
          <div className="container">
            <div className="d-sm-flex d-block justify-content-between align-items-end pb-5">
              <div className="mainHd whtClr text-sm-nowrap text-wrap">
                <span className="d-flex pb-3">Projects</span> Our Popular
                construction project
              </div>
              <div className="spacer"></div>
              {/* <div className="mt-sm-0 mt-3">
                <a href="#" className="viewMore">
                  View more
                </a>
              </div> */}
            </div>
            <Slider className="projectSlider" {...projectSlider}>
              <div className="item">
                <a href="#" className="inner">
                  <div className="img">
                    <img alt="Spalling Maintenance Works" src={ProjectImg1} />
                  </div>
                  <div className="det">
                    <span className="name">Spalling Maintenance Works</span>
                    For the last few years, we have been regularly inspecting
                    and maintaining stonework spalling issues. Ensuring the
                    stonework doesn't fall from the leading edges.
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#" className="inner">
                  <div className="img">
                    <img alt="Specialised Projects" src={ProjectImg2} />
                  </div>
                  <div className="det">
                    <span className="name">Specialised Projects</span>
                    Working alongside structural engineers performing stress
                    tests of the DS8 Structural cables with customised
                    stainless-steel clamps and hydraulic jacks to 250KN. One of
                    4 buildings in Europe that require this specialist testing.
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#" className="inner">
                  <div className="img">
                    <img alt="High Level Glass Replacements" src={ProjectImg3} />
                  </div>
                  <div className="det">
                    <span className="name">High Level Glass Replacements</span>
                    Trusted with difficult glass replacements throughout London. We have completed some of the most difficult replacements other companies say aren't possible.
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#" className="inner">
                  <div className="img">
                    <img alt="Spalling Maintenance Works" src={ProjectImg1} />
                  </div>
                  <div className="det">
                    <span className="name">Spalling Maintenance Works</span>
                    For the last few years, we have been regularly inspecting
                    and maintaining stonework spalling issues. Ensuring the
                    stonework doesn't fall from the leading edges.
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#" className="inner">
                  <div className="img">
                    <img alt="Specialised Projects" src={ProjectImg2} />
                  </div>
                  <div className="det">
                    <span className="name">Specialised Projects</span>
                    Working alongside structural engineers performing stress
                    tests of the DS8 Structural cables with customised
                    stainless-steel clamps and hydraulic jacks to 250KN. One of
                    4 buildings in Europe that require this specialist testing.
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#" className="inner">
                  <div className="img">
                    <img alt="High Level Glass Replacements" src={ProjectImg3} />
                  </div>
                  <div className="det">
                    <span className="name">High Level Glass Replacements</span>
                    Trusted with difficult glass replacements throughout London. We have completed some of the most difficult replacements other companies say aren't possible.
                  </div>
                </a>
              </div>
            </Slider>
          </div>
        </section>
        <section className="pt-5 pb-5">
          <div className="container">
              <div className="mainHd maxWidth text-center pb-sm-5 pb-4">
                <span className="d-block pb-3">What we do </span> We offer a
                wide range of construction services to meet your needs.
              </div>
            <div>
              <ul className="d-flex flex-wrap wwdList">
                <li className="col-lg-3 col-sm-6 col-12">
                  <div className="inner">
                    <div className="icon"><img alt="" src={OfferIcon1} /></div>
                    <div className="text text-center pt-3">All access inspections, remedial maintenance, emergency maintenance, glass replacements, specialist projects.</div>
                  </div>
                </li>
                <li className="col-lg-3 col-sm-6 col-12">
                  <div className="inner">
                    <div className="icon"><img alt="" src={OfferIcon2} /></div>
                    <div className="text text-center pt-3">We are experienced dealing with retail and building tenants to avoid disruption to their operations, providing a seamless inspection and maintenance model.</div>
                  </div>
                </li>
                <li className="col-lg-3 col-sm-6 col-12">
                  <div className="inner">
                    <div className="icon"><img alt="" src={OfferIcon3} /></div>
                    <div className="text text-center pt-3">We also have great working relationships with local authorities and contractors which eases works around London and surrounding areas.</div>
                  </div>
                </li>
                <li className="col-lg-3 col-sm-6 col-12">
                  <div className="inner">
                    <div className="icon"><img alt="" src={OfferIcon4} /></div>
                    <div className="text text-center pt-3">Flexibility is our biggest strength over competition. We are Canary Wharf based and can adapt our reporting system based on <a href="#">Read more...</a></div>
                  </div>
                </li>
              </ul>
            </div>
            </div>
        </section>
        <section className="inspectionsCon pt-5 pb-5">
            <div className="mainHd text-center pb-sm-5 pb-4">What we do - Inspections</div>
            <Slider className="inspectionsSlider" {...inspectionsSlider}>
              <div className="item"><img alt="" src={InspectionsImg1} /></div>
              <div className="item"><img alt="" src={InspectionsImg2} /></div>
              <div className="item"><img alt="" src={InspectionsImg3} /></div>
              <div className="item"><img alt="" src={InspectionsImg4} /></div>
              <div className="item"><img alt="" src={InspectionsImg5} /></div>
              <div className="item"><img alt="" src={InspectionsImg1} /></div>
              <div className="item"><img alt="" src={InspectionsImg2} /></div>
              <div className="item"><img alt="" src={InspectionsImg3} /></div>
              <div className="item"><img alt="" src={InspectionsImg4} /></div>
              <div className="item"><img alt="" src={InspectionsImg5} /></div>
            </Slider>
        </section>
        <section className="pt-5 pb-5">
          <div className="container">
          <div className="d-flex flex-wrap justify-content-between align-items-center maintenanceBox">
              <div className="left pe-sm-5 pe-0">
                <div className="mainHd pb-3">Façade Maintenance Programme</div>
                <div className="textBox pb-sm-5 pb-4">
                To increase efficiency, we created the Façade Maintenance Programme.<br /><br />
                If you consider all set up, toolbox talks, checks, permits, exclusion zones, roof books, building access and moving the cradle, only part of a shift is spent descending in the BMU itself.  We are far more efficient maintaining the building during inspections, it's where we specialise.
                </div>
                <div>
                  <a href="#" className="readMore d-flex align-items-center"><span className="material-icons">arrow_right_alt</span> Learn more</a>
                </div>
              </div>
              <div className="right"><img alt="Façade Maintenance Programme" src={maintenanceImg} /></div>
            </div>
          </div>
        </section>
        <section className="pb-5">
          <div className="container">
            <div className="mainHd text-center pb-4"><span className="d-block pb-3">Testimonial</span> What Our client say</div>
            <Slider {...settingsFor} ref={sliderForRef} className="sliderFor">
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
              <div className="item">From start to finish, Turnstone was professional, transparent, and reliable. They made the whole process stress-free!</div>
            </Slider>

            <Slider {...settingsNav} ref={sliderNavRef} className="sliderNav">
              <div className="item">
                <div className="img"><img alt="" src={UserImg1} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg2} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg3} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg4} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg5} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg1} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg2} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg3} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg4} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
              <div className="item">
                <div className="img"><img alt="" src={UserImg5} /></div>
                <div className="name"><span>Adwin Smith</span> Creative Director, New York </div>
              </div>
            </Slider>
          </div>
        </section>
        <section className="pb-5">
          <div className="container">
            <div className="contactBanner d-flex align-items-center">
              <div className="det">
                <div className="mainHd pb-3">Just one click away!</div>
                <div className="text pb-4">Contact us today to discuss your project and get a free, no-obligation quote. Our team is here to turn your vision into reality.</div>
                <a href="#" className="contactBtn">CONTACT US</a>
              </div>
              <div className="img"><img alt="" src={contactBanner} /></div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Index;
