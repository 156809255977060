import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import * as Yup from "yup";
import { useFormik, FormikProps } from "formik";
import TopImg from "../../assets/img/image1.png";
import Logo from "../../assets/img/logo.png";
import BotImg from "../../assets/img/image2.png";
import Email from "../../assets/img/email.png";
import Lock from "../../assets/img/lock.png";
import { formFields } from "../../components/messages";
import { loginInfo, forgotPasswordInfo } from "../../Typings/accountListing";
import { errorMsgs} from "../../context/notify";
import {
  submitLoginData,
  forgotPassword,
} from "../../redux/actions/accountAction";

const Login: FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoginView, setIsLoginView] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  const formik: FormikProps<loginInfo> = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required(formFields.userName),
      password: Yup.string().required(formFields.password),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setIsShowLoader(true);
      dispatch(
        submitLoginData(values, (response: any) => {
          formik.resetForm();
          // Clear previous localStorage data
         try{
          localStorage.clear();
          // Handle response based on status code
          if (response.statusCode === 0) {
            // Display error message
            errorMsgs(response.message);
          } else if(response.statusCode === 1) {
            // Store necessary data in localStorage
            localStorage.setItem("role",response.data.role);
            localStorage.setItem("userId", response.data.userId);
            navigate("/verify");
          }

         }catch(error){
          console.log(error);
         }finally{
          setSubmitting(false);
          setIsShowLoader(false);
         }
        })
      );
    },
  });
  const forgotformik: FormikProps<forgotPasswordInfo> = useFormik({
    initialValues: {
      userEmail: "",
    },
    //validationSchema: Yup.object({
    //    userEmail: Yup.string().required(formFields.email),
    //}),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setIsShowLoader(true);
      dispatch(
        forgotPassword(values, (response: any) => {
          try {
            formik.resetForm(); 
            localStorage.clear();
            console.log(response);
           if (response.statusCode === 1) {
              // Store necessary data in localStorage
              localStorage.setItem("userId", response.data.userId);
              localStorage.setItem("IsResetPassword", response.data.isResetPassword);
              navigate("/verify");
            }else{
              errorMsgs(response.message);
            }
          }
          catch (error) {

          } finally {
            setSubmitting(false);
            setIsShowLoader(false);
          }
        })
      );
    },
  });
  const setLoginView = (isView: boolean) => {
    setIsLoginView(isView);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const handleDataSubmit = (response: any) => {
    // Reset the form

    console.log(response);
    // Clear previous localStorage data
    localStorage.clear();
    // Handle response based on status code
    if (response.statusCode == 0) {
      // Display error message
      console.log(response);

      errorMsgs(response.message);
    } else {
      // Store necessary data in localStorage
      localStorage.setItem("userId", response.data.userId);
      formik.resetForm();
      // Navigate to the verify page
      navigate("/verify");
    }
  };
  const handleSubmit = (response: any) => {
    // Reset the form
    formik.resetForm();

    // Clear previous localStorage data
    localStorage.clear();
    if (response.data.statusCode === 0) {
      // Handle unexpected response format
      errorMsgs(response.data.message);
      return;
    }
    // Handle response based on status code
    if (response.statusCode === 0) {
      // Display error message
      errorMsgs(response.message);
    } else {
      // Store necessary data in localStorage
      localStorage.setItem("userId", response.data.data.userId);
      localStorage.setItem("IsResetPassword", response.data.data.isResetPassword);
      navigate("/verify");
    }
  };
  return (
    <div className="loginBg d-flex align-items-center justify-content-center">
      <div className="loginInner d-flex align-items-center justify-content-between">
        <NavLink to="/" className="backBtn">
          <span className="material-icons">west</span>
        </NavLink>
        <div className="left">
          <div className="topImg">
            <img alt="Top decoration" src={TopImg} />
          </div>
          <div className="logo d-flex justify-content-center">
            <img
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
              src={Logo}
            />
          </div>
          <div className="botImg d-flex justify-content-end">
            <img alt="Bottom decoration" src={BotImg} />
          </div>
        </div>
        <div className="lgoinForm">
          {isLoginView ? (
            <>
              {" "}
              <div className="loginHd exoFont text-center pb-3 pb-sm-5">
                Welcome!
              </div>
              <form onSubmit={formik.handleSubmit}>
                <ul className="loginForm">
                  <li>
                    <div className="position-relative">
                      <img className="icon" alt="Email icon" src={Email} />
                      <input
                        type="text"
                        placeholder="Username"
                        className="loginInput"
                        autoComplete="off"
                        {...formik.getFieldProps("userName")}
                        required
                      />
                      {formik.touched.userName && formik.errors.userName ? (
                        <div className="error">{formik.errors.userName}</div>
                      ) : null}
                    </div>
                  </li>
                  <li>
                    <div className="position-relative">
                      <img className="icon" alt="Lock icon" src={Lock} />
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="loginInput password"
                        {...formik.getFieldProps("password")}
                        required
                      />
                      <span
                        className="material-icons-outlined eye"
                        onClick={togglePasswordVisibility}
                      >
                        {isActive ? "visibility_off" : "visibility"}
                      </span>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </div>
                  </li>
                  <li className="text-right">
                    <a
                      href="#"
                      className="fpLink"
                      onClick={() => setLoginView(false)}
                    >
                      Forgot password?
                    </a>
                  </li>
                  <li className="pt-4">
                    <button
                      type="submit"
                      className="signinBtn position-relative"
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      {isShowLoader ? (
                        <div className="loader"></div>
                      ) : (
                        "Sign in"
                      )}
                    </button>
                  </li>
                </ul>
              </form>
            </>
          ) : (
            <>
              <div className="loginHd exoFont text-center pb-3 pb-sm-5">
                Reset password?
              </div>
              <div className="exoFont text-center pb-3 pb-sm-5">
                You will receive an e-mail with a link to reset your password.
                Please follow the instructions in this e-mail to finalize this
                procedure.
              </div>
              <form onSubmit={forgotformik.handleSubmit}>
                <ul className="loginForm">
                  <li>
                    <div className="position-relative">
                      <img className="icon" alt="Email icon" src={Email} />
                      <input
                        type="text"
                        placeholder="Email"
                        className="loginInput"
                        autoComplete="off"
                        {...forgotformik.getFieldProps("userEmail")}
                        required
                      />
                      {forgotformik.touched.userEmail &&
                        forgotformik.errors.userEmail ? (
                        <div className="error">
                          {forgotformik.errors.userEmail}
                        </div>
                      ) : null}
                    </div>
                  </li>
                  <li className="text-right">
                    <a
                      href="#"
                      className="fpLink"
                      onClick={() => setLoginView(true)}
                    >
                      Sign In?
                    </a>
                  </li>
                  <li className="pt-4">
                    <button
                      type="submit"
                      className="signinBtn position-relative"
                      disabled={
                        !forgotformik.isValid || forgotformik.isSubmitting
                      }
                    > {isShowLoader ? (
                      <div className="loader"></div>
                    ) : (
                      "Reset Password"
                    )}
                      
                    </button>
                  </li>
                </ul>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
