import API from "../../config/api";
import { serializeQueryParams } from "../../config/apiutlils";
import config from "../../config/config";
import { Errors, Success } from "../../context/notify";
import { DefaultParamsforNotification } from "../../Typings/dataListing";
import { accountActionTypes } from "../constants/ActionTypes";

export const submitLoginData = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    await API().post('api/LoginAuth/UserLogin', defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}

export const forgotPassword = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    await API().post('api/LoginAuth/ForgotPassword', defaultparams).then((response:any) =>{

    if (response.status === 200 && callBack) {
        callBack(response.data);       
    }
});
}

export const userResetPassword = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    await API().post('api/LoginAuth/ResetPassword', defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}

export const resetPassword = (resetPasswordInfo: any, callBack?: any) => async (dispatch: any) => {
    await API({}, true, true).post('reset_password', resetPasswordInfo)
        .then((response: any) => {
            if (response.status === 200) {
                Success(response.data.message);
                if (callBack) {
                    callBack();
                }
            }
            dispatch({ type: accountActionTypes.USER_RESET_PASSWORD_LINK, payload: response.data })
        })
        .catch((error: any) => {
            console.log(error);
        });
}

export const getProfile = (payload:any) => async (dispatch: any) => {
     var path = config.client.GetClientByClientId
    const response = await API("", true).get(path+'?userId='+payload.userId);
    dispatch({ type: accountActionTypes.GET_PROFILE_INFO, payload: response.data });
} 

export const updateProfile = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = config.client.AddClient
    await API().post(path, defaultparams).then((response: any) => {
        Success(response.data.message);
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}

export const changePassword = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    const response = await API("", true).post('/api/LoginAuth/ChangePassword', defaultparams);
    if (response.status === 200) {
        Success(response.data.message);
        if (callBack) {
            callBack();
        }
    }
    dispatch({ type: accountActionTypes.FATCH_CHANGE_PASSWORD, payload: response.data });
}

export const logoutUser = (callBack?: any) => async (dispatch: any) => {
    const response = await API("", true).get('logout');
    if (response.status === 200) {
        Success(response.data.message);
        if (callBack) {
            callBack(response);
        }
        else {
            callBack({ status: 0, message: 'Unexpected error occurred' });
        }
    }
    dispatch({ type: accountActionTypes.GET_LOGOUT, payload: response.data });
}

export const VerifyOtp = (userId: number, userAccessCode: string, callBack?: any) => async (dispatch: any) => {
    const defaultparams = { userId, userAccessCode }; 
    await API().post('api/LoginAuth/ValidateUser?userId=' + userId + '&userAccessCode=' + userAccessCode).then((response: any) => {
        if (response.status === 200 && callBack) {
            dispatch({ type: accountActionTypes.VERIFY_OTP, payload: response.data });
            callBack(response.data);
        }
    }).catch((err) => {
        if (callBack) callBack(err);
    });
};

export const fetchNotificationList = (defaultparams: DefaultParamsforNotification, isReset: boolean = false) => async (dispatch: any) => {
    var path = config.notification.getUsersNotification
    path += serializeQueryParams(defaultparams);
    const response = await API("", false, false, !isReset).get(path);
    if (response.data.data.queryResponse) {
        dispatch({
            type: accountActionTypes.FETCH_NOTIFICATION_LIST,
            payload: {
                queryResponse: response.data.data.queryResponse,
                totalrecords: response.data.data.totalrecords,
            },
            isReset: isReset,
        });
    } else {
        dispatch({ 
            type: accountActionTypes.FETCH_NOTIFICATION_LIST,
            payload: { queryResponse: [], totalrecords: 0 },
            isReset: isReset,
        });
    }    
}


export const ReadNotification = (datas: any) => async (dispatch: any) => {
    var path = config.notification.ReadNotification
    const response = await API("", true).patch(path +'?Userid='+ datas.Userid+'&notificationId=' +datas.notificationId);
    if (response.status === 200) {
        Success(response.data.message);
    }else{
        Errors(response.data.message);
    }
}

export const fetchPowerBiTokenData = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = config.PowerBI.GetPoweBiToken
    await API().get(path, defaultparams).then((response: any) => {
        Success(response.data.message);
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}