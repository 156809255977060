import {
    Routes,
    Route,
  } from 'react-router-dom';
import Login from '../pages/accounts/login';
import ResetPassword from '../pages/accounts/resetPassword';
import Verify from '../pages/accounts/verify';
import Index from '../pages';
import Dashboard from '../pages/dashboard/index';
import ClientList from '../pages/Client/client-list';
import ManageClient from '../pages/Client/manageclient';
import ManageProject from '../pages/Project/manageproject';
import ProjectList from '../pages/Project/project-list';
import CreateClient from '../pages/Client/create-client';
import CreateProject from '../pages/Project/create-project';



export default function Root() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="login" element={<Login />} />
                <Route path="resetpassword" element={<ResetPassword />} />
                <Route path="verify" element={<Verify />} />

                <Route path="dashboard" element={<Dashboard />} />
                <Route path="client-list" element={<ClientList/>} />
                <Route path="client" element={<ManageClient/>} />

                <Route path="project-list" element={<ProjectList/>} />
                <Route path="project" element={<ManageProject/>} />
                <Route path="client/create-client" element={<CreateClient/>} />
                <Route path="project/create-project" element={<CreateProject/>} />
                <Route path="project/create-project/:projectId/*" element={<CreateProject/>} />
                <Route path="/client/create-client/:userId/*" element={<CreateClient />} />

            </Routes>
        </>
        
    )
}


