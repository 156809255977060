export const serializeQueryParams = (paramObj :any) => {
    if (paramObj) {
      return `?${Object.keys(paramObj)
        .map((k) => {
          if (typeof paramObj[k] === "object") {
            return (
              paramObj[k] &&
              paramObj[k]
                .map((v :any) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
                .join("&")
            );
          }
          return `${encodeURIComponent(k)}=${encodeURIComponent(paramObj[k])}`;
        })
        .join("&")}`;
    }
    return "";
  };
  
  //export const deserializeQueryParams = (queryParam :any) => {
  //  if (queryParam) {
  //    const pairs = queryParam.replace("?", "").split("&");
  //    const result = any ;
  //    pairs.forEach((p :any) => {
  //      const pair = p.split("=");
  //      const key = pair[0];
  //      const value = decodeURIComponent(pair[1] || "");
  //      if (result[key]) {
  //        if (Object.prototype.toString.call(result[key]) === "[object Array]") {
  //          result[key].push(value);
  //        } else {
  //          result[key] = [result[key], value];
  //        }
  //      } else {
  //        result[key] = value;
  //      }
  //    });
  //    return JSON.parse(JSON.stringify(result));
  //  }
  //  return {};
  //};
  