import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { NavLink } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import Companies from "../../assets/img/total-companies.png";
import Revenue from "../../assets/img/total-revenue.png";
import ProfitRate from "../../assets/img/profit-rate.png";
import { PageSize, PageSize2 } from "../../config/constant";
import { assignProjects, GetClients } from "../../redux/actions/clientAction";
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination";
import { bool, number } from "yup";
import { Button, Dialog, DialogActions } from "@mui/material";
import {
  fetchProjectList,
  getprojectUserIdAsync,
} from "../../redux/actions/ProjectAction";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import { fatchClientOverview } from "../../redux/actions/dataActions";

const ManageClient: FC = () => {
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [clientList, SetClientList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });
  const [ClientOverview, Set_ClientOverview] = useState<{
    TotalAdmin: number;
    TotalClient: number;
    TotalUsers: number;
  }>();
  const dispatch = useDispatch<AppDispatch>();
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [sortBy, setSortBy] = useState("userId");
  const [sortOrder, setSortOrder] = useState("asc");
  const [clientId, setClientId] = useState("");
  const [openAssignProject, setOpenAssignProject] = useState(false);
  const [listRef, setListRef] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const projectList = useSelector(
    (state: RootState) => state.projectData.projectList
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const [assignProjectId, setAssignProjectId] = useState<number[]>([]);
  const [pageSizeSelect, setPageSizeSelect] = useState(PageSize2);
  useEffect(() => {
    if (initialLoad) {
      // Run only on the first render
      setIsLoading(true);
      searchClients();
      setInitialLoad(false); // Set guard variable to false after initial call
      fetchClinetOverview();

    } else {
      searchClients();
      fetchClinetOverview();
    }
  }, [CurrentPage, searchQuery, pageSizeSelect]);

  const searchClients = (
    column: string = sortBy,
    order: string = sortOrder
  ) => {
    var payload = {
      PageNumber: CurrentPage,
      PageSize: pageSizeSelect,
      SearchName: isSearch ? "" : searchText,
      SortColumn: column,
      SortOrder: order,
    };
    try {
      dispatch(
        GetClients(payload, (response: any) => {
          SetClientList(response.data);
        })
      );
    } catch (error) {
      console.error("Error fetching Client:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const closeSearch = () => {
    SetCurrentPage(1); // Reset pagination to the first page
    setSearchText(""); // Clear search input value
    setSearchQuery(""); // Clear the query used for filtering
    setIsSearch(false); // Exit search mode
    searchClients();
  };

  const handleSort = (column: string) => {
    const order = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column); // Set the column to sort by
    setSortOrder(order); // Toggle the sorting order
    searchClients(column, order);
  };

  const handleProjectAssignOpen = (id: string) => {
    dispatch(
      getprojectUserIdAsync({ Userid: id, Projectid: 0 }, (response: any) =>
        callbackProjectCheck(response)
      )
    );
    if (id) setClientId(id);
  };

  const fetchClinetOverview = async () => {
    dispatch(
      fatchClientOverview((response: any) => {
        if (response.data) {
          console.log(response.data);
          Set_ClientOverview({
            TotalAdmin: response.data.totalAdmin,
            TotalClient: response.data.totalClient,
            TotalUsers: response.data.totalUsers,
          });
        }
      })
    );
  };

  function callbackProjectCheck(response: any) {
    if (response.data) {
      const projectIds = response.data
        .split(",")
        .map((id: string) => parseInt(id.trim()));
      setAssignProjectId(projectIds);
    } else {
      setAssignProjectId([]);
    }
    getProjectList(true);
  }

  const [defaultParamsForProject, setDefaultParamsForProject] = useState({
    PageNumber: 1,
    PageSize: PageSize,
  } as any);
  let totalRecordCountForProject = projectList.count;
  const currentDefaultParamsForProject = defaultParamsForProject;

  const handleAfterSubmit = (resetForm?: any, isSubmit?: Boolean) => {
    resetForm();
    setOpenAssignProject(false);
  };

  const getProjectList = (isReset: boolean = false) => {
    currentDefaultParamsForProject.PageNumber = isReset
      ? 1
      : currentDefaultParamsForProject.PageNumber + 1;
    setDefaultParamsForProject(currentDefaultParamsForProject);
    dispatch(fetchProjectList(currentDefaultParamsForProject, isReset));
    setOpenAssignProject(true);
  };

  const handleClose = () => {
    setOpenAssignProject(false);
  };

  const getUserAssignModal = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openAssignProject}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div className="d-block w-75">
              <h4 className="board-title-h4">Select Project</h4>
            </div>
          </div>
          <div className="usergroup-container">
            <div className="mt-3">
              <Formik
                initialValues={{
                  projectId: assignProjectId, // Initialize with pre-selected project IDs
                }}
                //enableReinitialize={true} // Allows Formik to reinitialize when initialValues change
                validationSchema={Yup.object({
                  projectId: Yup.array().min(
                    1,
                    "Need to select at least one project"
                  ), // Ensure at least one checkbox is selected
                })}
                onSubmit={({ projectId }) => {
                  const assignProjectInfo = {
                    userId: clientId,
                    projectId: projectId.join(","), // Convert array to comma-separated string
                  };
                  setListRef(listRef + 1); // Refresh list
                  dispatch(assignProjects(assignProjectInfo)); // Dispatch updated data
                  setOpenAssignProject(false); // Close modal
                }}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <Form>
                    <div
                      id="scrollableDiv2"
                      style={{ height: "calc(100vh - 60Vh)", overflow: "auto" }}
                    >
                      <InfiniteScroll
                        dataLength={projectList.data.length}
                        next={getProjectList}
                        hasMore={
                          totalRecordCountForProject !== projectList.data.length
                        }
                        loader={<Loader />}
                        scrollableTarget="scrollableDiv2"
                        scrollThreshold="200px"
                      >
                        <table className="table table-borderless">
                          <thead>
                            <tr className="header-row">
                              <th className="col-first">Select</th>
                              <th className="col-4">Name</th>
                              <th>Project Number</th>
                              <th>Created</th>
                            </tr>
                          </thead>
                          <tbody>
                            {projectList.data.map((item: any) => (
                              <tr key={`Project${item.projectId}`}>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      name="projectId"
                                      value={item.projectId}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;

                                        const newValues = isChecked
                                          ? [
                                              ...values.projectId,
                                              item.projectId,
                                            ] // Add projectId if checked
                                          : values.projectId.filter(
                                              (id) => id !== item.projectId
                                            ); // Remove if unchecked

                                        setFieldValue("projectId", newValues); // Update Formik state
                                        setAssignProjectId(newValues); // Sync with selectedProjects state
                                      }}
                                      checked={values.projectId.includes(
                                        item.projectId
                                      )} // Pre-check based on `assignProjectId`
                                    />
                                    <label
                                      htmlFor={`project_${item.projectId}`}
                                    ></label>
                                  </div>
                                </td>
                                <td>{item.name}</td>
                                <td>{item.projectNumber}</td>
                                <td>
                                  {new Date(
                                    item.projectStartDate
                                  ).toLocaleDateString("en-GB")}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                    <DialogActions>
                      <div className="float-end">
                        <Button
                          type="button"
                          className="close-button"
                          autoFocus
                          onClick={() => handleAfterSubmit(resetForm, true)}
                        >
                          Close
                        </Button>
                        <Button className="save-button ms-3" type="submit">
                          Assign
                        </Button>
                      </div>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handlePageChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10);
    setPageSizeSelect(selectedPageSize); // Update page size
    SetCurrentPage(1); // Reset to the first page
  };
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Clients</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4">
                <NavLink
                  to="/client/create-client"
                  className="createLink d-inline-flex align-items-center"
                >
                  Create
                </NavLink>
              </div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-5 pb-xl-5">
            <ul className="d-flex flex-wrap topInfo">
              <li className="col-12 col-sm-6 col-lg-4">
                <div className="inner d-flex align-items-center p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={Companies} alt="" />
                  </div>
                  <div className="det ps-4">
                    Total Clients <span style={{ color: "#115D8B" }}>{ClientOverview?.TotalClient}</span>
                  </div>
                  <img src={Companies} alt="" className="bg" />
                </div>
              </li>
              <li className="col-12 col-sm-6 col-lg-4">
                <div className="inner d-flex align-items-center p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 175, 83, 0.19)" }}
                  >
                    <img src={Revenue} alt="" />
                  </div>
                  <div className="det ps-4">
                    Total Admin{" "}
                    <span style={{ color: "#11AF53" }}>{ClientOverview?.TotalAdmin}</span>
                  </div>
                  <img src={Revenue} alt="" className="bg" />
                </div>
              </li>
              <li className="col-12 col-sm-6 col-lg-4">
                <div className="inner d-flex align-items-center p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(255, 233, 198, 1)" }}
                  >
                    <img src={ProfitRate} alt="" />
                  </div>
                  <div className="det ps-4">
                    Total Users <span style={{ color: "#FF6600" }}>{ClientOverview?.TotalUsers}</span>
                  </div>
                  <img src={ProfitRate} alt="" className="bg" />
                </div>
              </li>
            </ul>
            <div className="filterCon d-flex flex-wrap align-items-center mt-4 mt-xl-5">
              <div className="filterBox filterSearchBox">
                <div className="filterSearch">
                  <input
                    type="text"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    className="input"
                    placeholder="Search..."
                  />
                  {!isSearch ? (
                    <button type="button" className="btn">
                      <span
                        className="material-icons"
                        onClick={() => {
                          SetCurrentPage(1);
                          setIsSearch(true);
                          searchClients();
                        }}
                      >
                        search
                      </span>
                    </button>
                  ) : (
                    <button type="button" className="btn">
                      <span
                        className="material-icons"
                        onClick={() => {
                          closeSearch();
                        }}
                      >
                        close
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handlePageChange}
                  name="pageChange"
                  id="pageChange"
                  value={pageSizeSelect}
                >
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div className="tableCov mt-4 mt-xl-5">
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th onClick={() => handleSort("name")}>
                        Name{" "}
                        {sortBy === "name"
                          ? sortOrder === "asc"
                            ? "▼▲"
                            : "▲▼"
                          : "▼▲"}
                      </th>
                      <th onClick={() => handleSort("email")}>
                        Email{" "}
                        {sortBy === "email"
                          ? sortOrder === "asc"
                            ? "▼▲"
                            : "▲▼"
                          : "▼▲"}
                      </th>
                      <th>Phone</th>
                      <th>Street</th>
                      <th>Assign </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={7} className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    clientList.queryResponse && (
                      <tbody>
                        {clientList.queryResponse.map((item: any) => (
                          <tr key={"Client" + item.userId}>
                            <td>{item.company}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phonenumber}</td>
                            <td>{item.street}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() =>
                                  handleProjectAssignOpen(item.userId)
                                }
                              >
                                Project
                              </button>
                            </td>
                            <td>
                              <div className="d-inline-flex align-items-center">
                                <Link
                                  to={{
                                    pathname:
                                      "/client/create-client/" + item.userId,
                                  }}
                                  className="btn btn-sm btn-success"
                                >
                                  <i className="fa fa-edit"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={clientList.pageIndex}
                PageSize={clientList.pageSize}
                TotalPages={clientList.totalPages}
                TotalCount={clientList.totalrecords}
                hasNextPage={clientList.hasNextPage}
                hasPreviousPage={clientList.hasPreviousPage}
              />
            </div>

            {clientList && clientList.queryResponse.length ? (
              <div className="mt-3">{getUserAssignModal()}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClient;
