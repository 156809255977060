import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo.png';

const Header: FC = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/login');
    };
    return (
       <>
            <header className="header">
                <div className="container">
                    <div className="top d-flex justify-content-end">
                        <ul className="d-sm-flex d-block align-items-center">
                            <li><span className="material-icons icon">location_on</span> 77 Marsh Wall SQB Building London E14 9SH</li>
                            <li><span className="material-icons icon">email</span> Info@turnstoneconstruction.co.uk</li>
                        </ul>
                    </div>
                    <div className="bot d-flex align-items-center justify-content-between">
                        <div className="logo"><img alt="" src={Logo} /></div>
                        <div><a href="" onClick={handleClick} className="signinLink d-flex align-items-center">Sign in</a></div>
                    </div>
                </div>
            </header>
       </>
    );
}

export default Header;