import { FC, useState, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import { formFields } from "../../components/messages";
import { errorMsgs} from "../../context/notify";
import { AddUserprofileParams, UserType } from "../../Typings/dataListing";
import { fetchUserType } from "../../redux/actions/dataActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  AddClientDetails,
  checkEmailAvailablity,
  GetClientsDetails,
} from "../../redux/actions/clientAction";
import {  number, string } from "yup";

// Service function to send data to the server

const CreateClient: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [userTypes, setUserTypes] = useState<UserType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { userId } = useParams();
  const [UserDetails, SetUserDetails] = useState({
    data: {},
    message: string,
    statusCode: number,
  });

  useEffect(() => {
    loadUserTypes();
    if(userId){
      getClientdate();
    }
  }, [dispatch]);

  const getClientdate = async () => {
    var payload =  {
      userId: Number(userId),
    };
    try {
      dispatch(
        GetClientsDetails( payload , (data: any) => {
          const userData = data?.data;
        if (userData) {
          formik.setFieldValue("userId", userData.userId || 0);
          formik.setFieldValue("firstName", userData.firstName || "");
          formik.setFieldValue("lastName", userData.lastName || "");
          formik.setFieldValue("email", userData.email || "");
          formik.setFieldValue("userName", userData.userName || "");
          formik.setFieldValue("company", userData.company || "");
          formik.setFieldValue("phoneNumber", userData.phoneNumber || "");
          formik.setFieldValue("position", userData.position || "");
          formik.setFieldValue("street", userData.street || "");
          formik.setFieldValue("zipCode", userData.zipCode || "");
          formik.setFieldValue("city", userData.city || "");
          formik.setFieldValue("userType", userData.userType || 0);
        }

          SetUserDetails({
            data: data.data,
            message: data.message,
            statusCode: data.statusCode,
          });
        })
      );
    } catch (error) {
      console.error("Error fetching user types:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadUserTypes = async () => {
    setLoading(true);
    try {
      dispatch(
        fetchUserType({}, (data: any) => {
          const formattedUserTypes = data.data.map((item: any) => ({
            value: item.userTypeId, // Adjust according to API response
            label: item.description, // Adjust according to API response
          }));
          setUserTypes(formattedUserTypes);
        })
      );
    } catch (error) {
      console.error("Error fetching user types:", error);
    } finally {
      setLoading(false);
    }
  };

  // Formik configuration

  const formik: FormikProps<AddUserprofileParams> = useFormik({
    initialValues: {
      userId: 0,
      firstName: "",
      lastName: "",
      userName: "",
      userType: 0,
      email: "",
      company: "",
      phoneNumber: "",
      position: "",
      street: "",
      zipCode: "",
      city: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(formFields.firstName),
      lastName: Yup.string().required(formFields.lastName),
      email: Yup.string()
        .email(formFields.email)
        .required(formFields.emailRequired),
      //userName: Yup.string().required(formFields.userName),
      company: Yup.string().required(formFields.company),
      phoneNumber: Yup.string().required(formFields.phone_no),
      street: Yup.string().required(formFields.street),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setIsShowLoader(true);
      //   console.log(values);
      dispatch(
        AddClientDetails(values, (response: any) => {
          formik.resetForm();
          try {
            if (response.statusCode === 0) {
              errorMsgs(response.message);
            } else if (response.statusCode === 1) {
              navigate("/client");
              //   console.log(response);
            }
            else if (response.statusCode === 2) {
                navigate("/client");
                //   console.log(response);
              }
          } catch (error) {
          } finally {
            setSubmitting(false);
            setIsShowLoader(false);
          }
          setIsShowLoader(false);
        })
      );
      setIsShowLoader(false);
    },
  });

  const checkEmailExists= (email:any) => {
    try {
      dispatch(checkEmailAvailablity(email, (response: any) => featchEmailExist(response)));
    } catch (error) {
      throw new Error("Failed to check email existence.");
    }
  }

  function featchEmailExist(response: any) {
    if(response.message == "Email already exist"){
      formik.setFieldError("email", "This email is already in use.");
      errorMsgs(response.message);
      formik.setFieldValue("email", ""); // Reset email field
    }
  }
  
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Create New Client</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-5 pb-xl-5">
            <div className="createForm p-3 p-sm-5">
              <form onSubmit={formik.handleSubmit}>
                <ul className="form d-flex flex-wrap">
                  {/* First Name */}
                  <li className="col-12 col-sm-6">
                    <span className="fieldName d-block pb-2">First Name</span>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter your first name"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <span className="error">{formik.errors.firstName}</span>
                    )}
                  </li>

                  {/* Last Name */}
                  <li className="col-12 col-sm-6">
                    <span className="fieldName d-block pb-2">Last Name</span>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Enter your last name"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <span className="error">{formik.errors.lastName}</span>
                    )}
                  </li>

                  {/* Email */}
                  
                  <li className="col-12">
                    <span className="fieldName d-block pb-2">
                      Email Address
                    </span>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      className="formInput"
                      onChange={async (e) => {
                        formik.handleChange(e); // Update the form state
                        const email = e.target.value; // Get the current input value
                        if (email) {
                          await checkEmailExists(e.target.value); // Call the checkEmailExists function
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email} 
                      disabled={userId? true: false}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <span className="error">{formik.errors.email}</span>
                    )}
                  </li>

                  {/* User Name */}
                  {!userId ? 
                  <li className="col-12">
                    <span className="fieldName d-block pb-2">User Name</span>
                    <input
                      type="text"
                      name="userName"
                      placeholder="Enter your user name"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.userName}
                    />
                    {formik.touched.userName && formik.errors.userName && (
                      <span className="error">{formik.errors.userName}</span>
                    )}
                  </li> : "" }

                  {/* Company */}
                  <li className="col-12">
                    <span className="fieldName d-block pb-2">Company</span>
                    <input
                      type="text"
                      name="company"
                      placeholder="Enter your company name"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company}
                    />
                    {formik.touched.company && formik.errors.company && (
                      <span className="error">{formik.errors.company}</span>
                    )}
                  </li>
                  {/* Position */}
                  <li className="col-12">
                    <span className="fieldName d-block pb-2">Position</span>
                    <input
                      type="text"
                      name="position"
                      placeholder="Enter your Position"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.position}
                    />
                    {formik.touched.position && formik.errors.position && (
                      <span className="error">{formik.errors.position}</span>
                    )}
                  </li>

                  {/* User Type */}
                  <li className="col-12 col-sm-4">
                    <span className="fieldName d-block pb-2">User Type</span>
                    <select
                      name="userType"
                      className="formSelect"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "userType",
                          Number(e.target.value)
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.userType}
                    >
                      <option value="0">Select</option>
                      {userTypes.map((userType) => (
                        <option key={userType.value} value={userType.value}>
                          {userType.label}
                        </option>
                      ))}
                    </select>
                    {formik.touched.userType && formik.errors.userType && (
                      <span className="error">{formik.errors.userType}</span>
                    )}
                  </li>

                  {/* Phone Number */}
                  <li className="col-12 col-sm-8">
                    <span className="fieldName d-block pb-2">Phone Number</span>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                    />
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber && (
                        <span className="error">
                          {formik.errors.phoneNumber}
                        </span>
                      )}
                  </li>

                  {/* Street */}
                  <li className="col-12">
                    <span className="fieldName d-block pb-2">Street</span>
                    <input
                      type="text"
                      name="street"
                      placeholder="Enter your street"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.street}
                    />
                    {formik.touched.street && formik.errors.street && (
                      <span className="error">{formik.errors.street}</span>
                    )}
                  </li>
                  {/* Zip Code */}
                  <li className="col-12 col-sm-4">
                    <span className="fieldName d-block pb-2">Zip Code</span>
                    <input
                      type="text"
                      name="zipCode"
                      placeholder="Enter your Zip Code"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipCode}
                    />
                    {formik.touched.zipCode && formik.errors.zipCode && (
                      <span className="error">{formik.errors.zipCode}</span>
                    )}
                  </li>

                  {/* City */}
                  <li className="col-12 col-sm-8">
                    <span className="fieldName d-block pb-2">City</span>
                    <input
                      type="text"
                      name="city"
                      placeholder="Enter your City"
                      className="formInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <span className="error">{formik.errors.city}</span>
                    )}
                  </li>

                  {/* Buttons */}
                  <li className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="formBtn position-relative cancel"
                      onClick={() => navigate("/client")}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="formBtn position-relative ms-3"
                    >
                      {isShowLoader ? <div className="loader"></div> : "Save"}
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateClient;
