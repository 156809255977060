import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { errorMsgs } from "../../context/notify";
import { VerifyOtp } from "../../redux/actions/accountAction";
import { parseInt } from "lodash";

const Verify: FC = () => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [isResent, setIsResent] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  // Handle input change and auto-focus
  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value.toUpperCase();
    if (/^[0-9A-Z]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus next input if available
      if (
        element.nextSibling &&
        (element.nextSibling as HTMLInputElement).focus
      ) {
        (element.nextSibling as HTMLInputElement).focus();
      }
    }
  };

  // Verify OTP by combining all digits
  const verifyOtp = () => {
    const userAccessCode = otp.join("");
    const UserCode = localStorage.getItem("userId");
    const userId = UserCode ? parseInt(UserCode) : 1;

    if (userAccessCode.length === 6) {
      setIsShowLoader(true);
      dispatch(
        VerifyOtp(userId, userAccessCode, (response: any) => {
          try {
            if (response.statusCode === 1) {
              const isResetPassword = localStorage.getItem("IsResetPassword");
              if (isResetPassword) {
                navigate("/resetPassword");
              } else {
                navigate("/dashboard");
                localStorage.setItem(
                  "lusername",
                  response.data.userloginModel.name
                );
                localStorage.setItem("accessToken", response.data.accessToken);
                localStorage.setItem(
                  "refreshToken",
                  response.data.refreshToken
                );
              }
            } else {
              errorMsgs("Verification failed. Please try again.");
            }
          } catch (error) {
          } finally {
            setIsShowLoader(false);
          }
        })
      );
    } else {
      alert("Please enter all 6 digits.");
    }
  };

  // Resend OTP logic
  const resendOtp = () => {
    // Add logic to resend OTP (e.g., API call)
    setIsResent(true);
    setTimeout(() => setIsResent(false), 3000); // Reset resend message after 3 seconds
  };

  // Cancel action logic
  const cancelVerification = () => {
    setOtp(new Array(6).fill(""));
  };

  return (
    <>
      <div className="loginBg d-flex align-items-center justify-content-center">
        <div className="loginInner d-flex align-items-center justify-content-center">
          <NavLink to="/" className="backBtn">
            <span className="material-icons">west</span>
          </NavLink>
          <div className="verifyBox">
            <div className="loginHd exoFont text-center">
              Verify your Account!
            </div>
            <div className="text-center hdText pb-5">
              Enter the 6-digit OTP we sent to your email address
            </div>
            <div className="d-flex justify-content-between otpContainer">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  className="otpInput"
                  maxLength={1}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()} // Select text on focus
                />
              ))}
            </div>
            <div className="text-center hdText pt-5">
              Didn't get a code?{" "}
              <a href="#" onClick={resendOtp}>
                click to resend
              </a>
              {isResent && <div className="resendMessage">Code resent!</div>}
            </div>
            <div className="d-flex justify-content-between pt-4">
              <button
                type="button"
                className="signinBtn cancel me-3"
                onClick={cancelVerification}
              >
                Cancel
              </button>
              <button
                type="button"
                className="signinBtn ms-3 position-relative"
                disabled={isShowLoader}
                onClick={verifyOtp}
              >
                {isShowLoader ? <div className="loader"></div> : "Verify"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
