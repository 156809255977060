import {
    Routes,
    Route,
    Navigate,
  } from 'react-router-dom';
  import Login from '../pages/accounts/login';
  import ResetPassword from '../pages/accounts/resetPassword';
  import Verify from '../pages/accounts/verify';
  import Index from '../pages';
  import Dashboard from '../pages/dashboard/index';
  import ClientList from '../pages/Client/client-list';
  import ManageClient from '../pages/Client/manageclient';
  import ManageProject from '../pages/Project/manageproject';
  import ProjectList from '../pages/Project/project-list';
  import CreateClient from '../pages/Client/create-client';
  import CreateProject from '../pages/Project/create-project';
  import TicketList from '../pages/Tickets/ticketList';
  import NewTicketList from '../pages/Tickets/newTicketList';
  import QuotationList from '../pages/Tickets/quotationList';
  import Profile from '../pages/accounts/profile';
  import ChangePassword from '../pages/accounts/changePassword';
  import ChatApp from '../pages/chat/chatapp';
  import { useState } from 'react';
  
  export default function Root() {
    const [user, setUser] = useState(localStorage.getItem("role"));
  
    return (
      <>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="login" element={<Login />} />
          <Route path="resetpassword/:userId" element={<ResetPassword />} />
          <Route path="verify" element={<Verify />} />
  
          {/* Conditional routes that require user to be logged in */}
          {user ? (
            <>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="client-list" element={<ClientList />} />
              <Route path="client" element={<ManageClient />} />
              <Route path="project-list" element={<ProjectList />} />
              <Route path="project" element={<ManageProject />} />
              <Route path="client/create-client" element={<CreateClient />} />
              <Route path="project/create-project" element={<CreateProject />} />
              <Route path="project/create-project/:projectId/*" element={<CreateProject />} />
              <Route path="/client/create-client/:userId/*" element={<CreateClient />} />
              <Route path="tickets" element={<TicketList />} />
              <Route path="tickets/:projectId" element={<TicketList />} />
              <Route path="newTickets/" element={<NewTicketList />} />
              <Route path="quotation" element={<QuotationList />} />
              <Route path="update-profile" element={<Profile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="ChatApp" element={<ChatApp />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </>
    );
  }
  