import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { Errors } from './../context/notify';

const APIConfig: AxiosRequestConfig = {
    baseURL: "https://localhost:44311",
    // baseURL:process.env.REACT_APP_API_SERVER,
};


function updateHeaders(headers: Partial<AxiosRequestHeaders>) {
    APIConfig.headers = Object.assign(APIConfig.headers || {}, headers);
}

function handleError(err: any) {
    if (typeof err === 'object' && err.response) {
        if (err.response.status === 401) {
            showError(err.response.data);
        } else if (err.response.data && (err.response.data.error || err.response.data.Error)) {
            const errorMessage = err.response.data.error ?? err.response.data.Error;
            showError(errorMessage);
        } else if (err.message) {
            showError({ message: err.message });
        }
    } else {
        showError({ message: 'An unknown error occurred' });
    }
}

function showError(object: object) {
    const errors = Object.values(object);
    const errorCollection: string[] = errors.flat();
    Errors(errorCollection);
}

function API(headers?: Object, bypassOidc: boolean = false, byPassNotistack: boolean = false, byPassLoader: boolean = false): AxiosInstance {
    const instance = axios.create(APIConfig);
    
    instance.interceptors.request.use((config:any) => {
        const userId = localStorage.getItem('userId');
        const accessToken = localStorage.getItem('accessToken');
        if (userId && accessToken) {
           // const { id: userId, accessToken } = JSON.parse(userdata);
            if (userId && accessToken) {
                config.headers = {
                    ...config.headers,
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                   // 'userId': userId
                };
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    if (!byPassNotistack) {
        instance.interceptors.response.use(undefined, (err: any) => {
            if (typeof err === 'object') {
                handleError(err);
                // console.log(err);
                // if (err.response.status === 401) {
                //     return Promise.reject(err.response);
                // } else {
                    return Promise.reject(err.response);
                // }
            }
            return Promise.reject(err);
        });
    }

    return instance;
}

export default API;
export {
    API,
    APIConfig
};
