import { FC } from "react";
import { useNavigate } from "react-router-dom";
import UserImg from '../../assets/img/user-img1.jpg';

interface ToggleMenuProps {
    onClick: () => void; 
  }

const DashboardHeader: React.FC<ToggleMenuProps> = ({ onClick }) => {
    const toggleMenu = () => {
        onClick();
      };
    return (
       <>
           
                <div className="me-2 me-md-4"><a href="#" className="notificationLink d-flex"><span className="material-icons icon">notifications</span><span className="status"></span></a></div>
                <div className="me-2 me-md-4"><a href="#" className="mailLink d-flex"><span className="material-icons icon">email</span></a></div>
                <div className="acLinkBox"><a href="#" className="acLink d-flex align-items-center"><img src={UserImg} alt="" className="img me-2" /> <span className="text me-2">John wick</span> <span className="material-icons arrow">expand_more</span></a></div>
                <a className="menuToggle" onClick={toggleMenu}>
                    <span className="material-icons">menu</span>
                </a>
        
       </>
    );
}

export default DashboardHeader;