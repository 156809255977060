import { dataActionTypes, projectActionTypes } from "../constants/ActionTypes";


const initialState = {
    projectList: {
        data: [],
        count: 0
    },
    parentProjectList: {
        data: [],
        count: 0
    },
}

export const projectReducer = (state = initialState, { type, payload,isReset }: any) => {
    switch (type) {
        case projectActionTypes.FETCH_PROJECT_LIST: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset
                ? queryResponse // Replace existing data if `isReset` is true
                : [...state.projectList.data, ...queryResponse]; // Append new data to existing data

            return {
                ...state,
                projectList: {
                    data: data,
                    count: payload.totalrecords || state.projectList.count, // Update count or fallback to existing count
                },
            };
        } 
        case dataActionTypes.FETCH_PARENT_PROJECT: {
                    const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
                    const data = isReset !== false
                        ? queryResponse
                        : [...state.parentProjectList.data, ...queryResponse];
                
                    return {
                        ...state,
                        parentProjectList: {
                            data: data,
                            count: payload.totalrecords || state.parentProjectList.count,
                        },
                    };
                }
        default:
            return state

    }
} 