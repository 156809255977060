import { FC, useEffect, useRef, useState } from "react";
import DashboardMenu from "../layouts/dashboardmenu";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination";
import { bool, number } from "yup";
import { PageSize2 } from "../../config/constant";
import {
  GetTickets,
  GetTicketStatusByTicketId,
} from "../../redux/actions/ticketAction";
import Project from "../../assets/img/total-project.png";
import InActiveProject from "../../assets/img/in-active-projects.png";
import ProjectStatus from "../../assets/img/project-status.png";
import { Button, Dialog, DialogActions } from "@mui/material";
import {
  getAllProject,
  UpdateProjectStatus,
} from "../../redux/actions/ProjectAction";
import {
  GetChartsData,
  GetIssuePriorities,
  GetIssueStatus,
} from "../../redux/actions/dataActions";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { errorMsgs } from "../../context/notify";
import Loader from "../../components/Loader";
import { NULL } from "sass";

const TicketList: FC = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [sortBy, setSortBy] = useState(""); // column to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [isActive, setIsActive] = useState(false);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [openViewTicket, setopenViewTicket] = useState(false);
  const [openViewTicketStatus, setOpenViewTicketStatus] = useState(false);
  const [ticketViewData, setTicketViewData] = useState(Object);
  const [priorityId, setpriorityId] = useState<number | null>(null);
  const [statusId, setStatusId] = useState<number | null>(null);
  const [ticketId, setTicketId] = useState(number);
  const [viewStatusData, setViewStatusData] = useState([]);
  const [openTicketStatusModal, setOpenTicketStatusModal] = useState(false);
  const projectList = useSelector(
    (state: RootState) => state.projectData.projectList
  );
  const prioritiesList = useSelector(
    (state: RootState) => state.accountData.prioritiesList
  );
  const statusList = useSelector(
    (state: RootState) => state.accountData.statusList
  );
  const KPIData = useSelector((state: RootState) => state.accountData.KPIData);
  const didRun = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSizeSelect, setPageSizeSelect] = useState(PageSize2);

  const [ticketList, SetTicketList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const getProjectList = () => {
    if (didRun.current) return; // Skip if already executed
    didRun.current = true; // Mark as executed
    setIsLoading(true);
    try {
      dispatch(getAllProject());
      dispatch(GetIssuePriorities());
      dispatch(GetIssueStatus());
      dispatch(GetChartsData());
    } catch (error) {
      console.error("Error fetching project data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProjectList();
  }, []);

  useEffect(() => {
    searchTickets();
  }, [CurrentPage, searchQuery, pageSizeSelect]);

  const searchTickets = (
    column: string = sortBy,
    order: string = sortOrder,
    priority: number | null = priorityId,
    status: number | null = statusId
  ) => {
    const payload: Record<string, any> = {
      projectId: projectId || 0,
      PageNumber: CurrentPage,
      PageSize: pageSizeSelect,
      Search: isSearch ? "" : searchText,
      SortColumn: column,
      SortOrder: order,
    };

    if (priority) {
      payload.IssuePriorityId = priority;
    }
    if (status) {
      payload.IssueStatusId = status;
    }
    try {
      dispatch(
        GetTickets(payload, (response: any) => {
          SetTicketList(response.data); // Replace existing data with new data
        })
      );
    } catch (error) {
      console.error("Error fetching tickets:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const closeSearch = () => {
    SetCurrentPage(1); // Reset pagination to the first page
    setSearchText(""); // Clear search input value
    setSearchQuery(""); // Clear the query used for filtering
    setIsSearch(false); // Exit search mode
    searchTickets();
  };

  const handleSort = (column: string) => {
    const order = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column); // Set the column to sort by
    setSortOrder(order); // Toggle the sorting order
    searchTickets(column, order);
  };

  const handleViewTicketOpen = (datas: any) => {
    setTicketViewData(datas);
    setopenViewTicket(true);
  };

  const handleClose = () => {
    setopenViewTicket(false);
    setOpenTicketStatusModal(false);
  };

  const getTicketViewDetails = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openViewTicket}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3 ticket-details">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-4">View Ticket Details</h4>
              <p>
                <b>Ticket Number : </b>
                {ticketViewData.ticketNumber}
              </p>
              <p>
                <b>Subject : </b>
                {ticketViewData.subject}
              </p>
              <p>
                <b>Priority : </b> {ticketViewData.priorityName}
              </p>
              <p>
                <b>Status : </b> {ticketViewData.statusName}
              </p>
              <p>
                <b>Ticket Type : </b> {ticketViewData.ticketTypeName}
              </p>
              <p>
                <b>Assign Client Name : </b> {ticketViewData.customerId}
              </p>
              <p>
                <b>Create Date : </b>{" "}
                {ticketViewData.createdAt
                  ? new Date(ticketViewData.createdAt).toLocaleDateString(
                      "en-GB"
                    )
                  : ""}
              </p>
              <p>
                <b>Extension Date : </b>{" "}
                {ticketViewData.extensionDate
                  ? new Date(ticketViewData.extensionDate).toLocaleDateString(
                      "en-GB"
                    )
                  : ""}{" "}
              </p>
              <p>
                <b>Due Date : </b>{" "}
                {ticketViewData.dueDate
                  ? new Date(ticketViewData.dueDate).toLocaleDateString("en-GB")
                  : ""}{" "}
              </p>
              <DialogActions>
                <div className="float-end">
                  <Button
                    type="button"
                    className="close-button btn-warning"
                    autoFocus
                    onClick={() => handleClose()}
                  >
                    Close
                  </Button>
                </div>
              </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleProjectChange = (event: any) => {
    const selectedProjectId = event.target.value;
    navigate(`/tickets/${selectedProjectId}`);
    window.location.reload(); 
  };

  const handleProjectPriority = (event: any) => {
    const selectedPriorityId = event.target.value;
    SetCurrentPage(1);
    setpriorityId(selectedPriorityId); 
    searchTickets(undefined, undefined, selectedPriorityId, statusId); 
  };

  const handleProjectStatus = (event: any) => {
    const selectedStatusId = event.target.value;
    SetCurrentPage(1);
    setStatusId(selectedStatusId); 
    searchTickets(undefined, undefined, priorityId, selectedStatusId); 
  };

  const handleStatusChangeOpen = (id: any) => {
    setTicketId(id);
    setOpenTicketStatusModal(true);
  };

  const handleAfterSubmit = (resetForm?: any, isSubmit?: Boolean) => {
    resetForm();
    setOpenTicketStatusModal(false);
  };

  const getTicketStatusChange = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openTicketStatusModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-4">Change Ticket Status</h4>
              <div className="usergroup-container">
                <div className="mt-3">
                  <Formik
                    initialValues={{
                      TicketFeedback: "",
                      StatusId: "",
                      TicketId: ticketId,
                      UserId: parseInt(
                        localStorage.getItem("userId") || "0",
                        10
                      ),
                      CreatedBy: parseInt(
                        localStorage.getItem("userId") || "0",
                        10
                      ),
                    }}
                    validationSchema={Yup.object({
                      TicketFeedback: Yup.string().required(
                        "Please Enter Feedback"
                      ),
                      StatusId: Yup.string().required(
                        "Please Select at leate one status"
                      ),
                    })}
                    onSubmit={(initialValues) => {
                      dispatch(UpdateProjectStatus(initialValues));
                      setOpenTicketStatusModal(false);
                      window.location.reload();
                    }}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      errors,
                      touched,
                      resetForm,
                    }) => (
                      <Form>
                        <div className="group-form mb-2">
                          <label
                            htmlFor="group_name"
                            className="group-label mb-1"
                          >
                            Select Status
                          </label>
                          <select
                            className="form-control project-filter"
                            name="StatusId"
                            id="StatusId"
                            onChange={handleChange}
                            value={values.StatusId}
                          >
                            <option>Ticket Status</option>
                            {statusList.data.map((statusData: any) => (
                              <option
                                key={statusData.issueStatusId}
                                value={statusData.issueStatusId}
                              >
                                {statusData.status}
                              </option>
                            ))}
                          </select>
                          <>
                            {touched.StatusId && errors.StatusId
                              ? errorMsgs(errors.StatusId)
                              : null}
                          </>
                        </div>
                        <div className="group-form mb-2">
                          <label htmlFor="group_name" className="group-label">
                            Feedback
                          </label>
                          <textarea
                            id="TicketFeedback"
                            name="TicketFeedback"
                            placeholder="Enter Feedback"
                            className="info-create-group form-control mt-2"
                            onChange={handleChange}
                            rows={5}
                            value={values.TicketFeedback}
                          ></textarea>
                          <>
                            {touched.TicketFeedback && errors.TicketFeedback
                              ? errorMsgs(errors.TicketFeedback)
                              : null}
                          </>
                        </div>

                        <DialogActions>
                          <div className="float-end">
                            <Button
                              type="button"
                              className="close-button btn-warning"
                              autoFocus
                              onClick={() => handleAfterSubmit(resetForm, true)}
                            >
                              Close
                            </Button>
                            <Button
                              className="save-button btn btn-primary ms-3"
                              type="submit"
                            >
                              Send
                            </Button>
                          </div>
                        </DialogActions>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleViewTicketStatusOpen = (ticketId: any) => {
    dispatch(
      GetTicketStatusByTicketId({ ticketId: ticketId }, (response: any) =>
        callbackViewStatus(response)
      )
    );
  };
  function callbackViewStatus(response: any) {
    setViewStatusData(response.data);
    setOpenViewTicketStatus(true);
  }
  const getTicketStatusDetails = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openViewTicketStatus}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3 ticket-details">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-4">View Status Details</h4>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Feedback</th>
                  </tr>
                </thead>
                <tbody>
                  {viewStatusData.length > 0 ? (
                    viewStatusData.map((tdata: any, index: any) => (
                      <tr key={index}>
                        <td>{tdata.status}</td>
                        <td>{tdata.ticketFeedback}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2}>No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <DialogActions>
                <div className="float-end">
                  <Button
                    type="button"
                    className="close-button btn-warning"
                    autoFocus
                    onClick={() => setOpenViewTicketStatus(false)}
                  >
                    Close
                  </Button>
                </div>
              </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handlePageChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10);
    setPageSizeSelect(selectedPageSize); // Update page size
    SetCurrentPage(1); // Reset to the first page
  };
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Tickets</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4"></div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-3 pb-xl-3">
            <ul className="d-flex flex-wrap projectTopInfo">
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={Project} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Total Project
                    <span style={{ color: "#115D8B" }}>
                      {KPIData.totalProject}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={Project} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Assign Project{" "}
                    <span style={{ color: "#115D8B" }}>
                      {KPIData.assignedProjects}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={InActiveProject} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Assign Tickets{" "}
                    <span style={{ color: "#115D8B" }}>
                      {KPIData.assignedTicket}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner d-flex align-items-center p-4 projectStatus">
                  <div className="left">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: "rgba(255, 233, 198, 1)" }}
                    >
                      <img src={ProjectStatus} alt="" />
                    </div>
                    <div className="det text-center pt-4">Ticket Status</div>
                  </div>
                  <div className="right ps-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Pending</span>
                      <span className="rgt" style={{ color: "#FF6600" }}>
                        {KPIData.pendingTicket}{" "}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Closed</span>
                      <span className="rgt" style={{ color: "#115D8B" }}>
                        {KPIData.closedTicket}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Rejected</span>
                      <span className="rgt" style={{ color: "#ca1e42" }}>
                        {KPIData.rejectedtickets}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Resolved</span>
                      <span className="rgt" style={{ color: "#138944" }}>
                        {KPIData.resolved}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="filterCon d-flex flex-wrap align-items-center mt-4 mt-xl-3">
              <div className="filterBox filterSearchBox">
                <div className="filterSearch">
                  <input
                    type="text"
                    onChange={(event) => setSearchText(event.target.value)}
                    className="input"
                    placeholder="Search By Subject..."
                  />
                  {!isSearch ? (
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        SetCurrentPage(1);
                        setIsSearch(true);
                        searchTickets();
                      }}
                    >
                      <span className="material-icons">search</span>
                    </button>
                  ) : (
                    <button type="button" className="btn">
                      <span
                        className="material-icons"
                        onClick={() => {
                          closeSearch();
                        }}
                      >
                        close
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="filterSearch ms-1">
                <select
                  className="form-control project-filter"
                  onChange={handleProjectChange}
                  name="seacrhByProject"
                  id="seacrhByProject"
                >
                  <option>Search Project</option>
                  {projectList.data.map((projectData: any) => (
                    <option
                      key={projectData.projectId}
                      value={projectData.projectId}
                    >
                      {projectData.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handleProjectPriority}
                  name="seacrhByProjectStatus"
                  id="seacrhByProjectStatus"
                >
                  <option>Ticket Priority</option>
                  {prioritiesList.data.map((priorityData: any) => (
                    <option
                      key={priorityData.issuePrioritiesId}
                      value={priorityData.issuePrioritiesId}
                    >
                      {priorityData.priority_Level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handleProjectStatus}
                  name="seacrhByProjectStatus"
                  id="seacrhByProjectStatus"
                >
                  <option>Ticket Status</option>
                  {statusList.data.map((statusData: any) => (
                    <option
                      key={statusData.issueStatusId}
                      value={statusData.issueStatusId}
                    >
                      {statusData.status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handlePageChange}
                  name="pageChange"
                  id="pageChange"
                  value={pageSizeSelect}
                >
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div className="tableCov mt-4 mt-xl-5">
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th onClick={() => handleSort("subject")}>
                        Subject{" "}
                        {sortBy === "subject"
                          ? sortOrder === "asc"
                            ? "▼▲"
                            : "▲▼"
                          : "▼▲"}
                      </th>
                      <th onClick={() => handleSort("priorityName")}>
                        Priority{" "}
                        {sortBy === "priorityName"
                          ? sortOrder === "asc"
                            ? "▼▲"
                            : "▲▼"
                          : "▼▲"}
                      </th>
                      <th>Ticket Type</th>
                      <th>Location</th>
                      <th>Status</th>
                      {localStorage.getItem("role") === "Admin" ? (
                        <th>Assignee</th>
                      ) : (
                        ""
                      )}
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td
                          colSpan={
                            localStorage.getItem("role") === "Admin" ? 8 : 7
                          }
                          className="text-center"
                        >
                          <Loader />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ticketList.queryResponse && (
                      <tbody>
                        {ticketList.queryResponse.map(
                          (item: any, index: any) => (
                            <tr key={"Ticket" + item.projectId}>
                              <td>{index + 1}</td>
                              <td>
                                {item.subject.length > 20
                                  ? `${item.subject.substring(0, 20)}...`
                                  : item.subject}
                              </td>
                              <td>{item.priorityName}</td>
                              <td>{item.ticketTypeName}</td>
                              <td>
                                <button className="btn btn-sm btn-primary">
                                  View
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-info"
                                  onClick={() =>
                                    handleViewTicketStatusOpen(item.ticketId)
                                  }
                                >
                                  {item.statusName}
                                </button>
                              </td>
                              {localStorage.getItem("role") === "Admin" ? (
                                <td>{item.assignStatus}</td>
                              ) : (
                                ""
                              )}
                              <td>
                                <button
                                  className="btn btn-sm btn-info"
                                  onClick={() => handleViewTicketOpen(item)}
                                >
                                  <i className="fa fa-eye"></i>
                                </button>
                                {localStorage.getItem("role") != "Admin" ? (
                                  item.statusName != "Closed" ? (
                                    <button
                                      className="btn btn-sm btn-warning ms-2"
                                      onClick={() =>
                                        handleStatusChangeOpen(item.ticketId)
                                      }
                                    >
                                      Change Status
                                    </button>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  <button
                                    className="btn btn-sm btn-warning ms-2"
                                    onClick={() =>
                                      handleStatusChangeOpen(item.ticketId)
                                    }
                                  >
                                    Change Status
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    )
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={ticketList.pageIndex}
                PageSize={ticketList.pageSize}
                TotalPages={ticketList.totalPages}
                TotalCount={ticketList.totalrecords}
                hasNextPage={ticketList.hasNextPage}
                hasPreviousPage={ticketList.hasPreviousPage}
              />
            </div>
          </div>
        </div>
      </div>
      {getTicketViewDetails()}
      {getTicketStatusChange()}
      {getTicketStatusDetails()}
    </>
  );
};
export default TicketList;
