import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { formFields } from "../../components/messages";
import * as Yup from 'yup';
import { FormikProps, useFormik } from "formik";
import { changePasswordInfo } from "../../Typings/accountListing";
import { handleError } from "../../context/notify";
import PasswordStrengthBar from "react-password-strength-bar";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import { changePassword } from "../../redux/actions/accountAction";

const ChangePassword: FC = () => {
    const [isActive, setIsActive] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    
    const formik: FormikProps<changePasswordInfo> = useFormik({
        initialValues: {
            userId: parseInt(localStorage.getItem("userId") || "0", 10) ,
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required(formFields.currentPassword),
            newPassword: Yup.string().required(formFields.password),
            confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword')], 'Passwords must match')
            .required('Password confirmation is required')
        }), 
        onSubmit: values => { 
            dispatch(changePassword(values,() => { handleDataSubmit() }));
            formik.resetForm();
        }, 
    }); 

    const handleDataSubmit = () => {
        formik.resetForm();
    }
    return ( 
        <>
            <div className={isActive ? "dbCon active" : "dbCon"}>
                <DashboardMenu />
                <div className="dbRight">
                    <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
                        <div className="dbTopHd">Change Password</div>
                        <div className="d-flex align-items-center justify-content-between hraderRgt">
                        <DashboardHeader onClick={toggleMenu} />
                        </div>
                    </header>
                    <div className="midInner pt-4 pb-4 pt-xl-5 pb-xl-5">
                        <div className="createForm p-3 p-sm-5">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-data">
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <input type="password" placeholder="Old Password" className="form-control mb-4"
                                                onChange={formik.handleChange} id="oldPassword" name="oldPassword"
                                                value={formik.values.oldPassword}
                                                autoComplete="off"></input>
                                            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                                handleError(formik.errors.oldPassword)
                                            ) : null}
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <input type="password" placeholder="New Password" className="form-control mb-4"
                                                onChange={formik.handleChange} id="newPassword" name="newPassword"
                                                value={formik.values.newPassword}
                                                autoComplete="off"></input>
                                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                                handleError(formik.errors.newPassword)
                                            ) : null}
                                            <PasswordStrengthBar password={formik.values.newPassword} />
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <div className="form-data mt-3">
                                                <input type="password" placeholder="New Confirm Password" className="form-control mb-4" autoComplete="off"
                                                    onChange={formik.handleChange} id="confirmPassword" name="confirmPassword"
                                                    value={formik.values.confirmPassword}></input>
                                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                    handleError(formik.errors.confirmPassword)
                                                ) : null}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="text-center mt-3">
                                    <button type="submit" className="btn btn-primary">Change Password <i className="far fa-paper-plane"></i> </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    );
}
export default ChangePassword;