import { clientActionTypes } from "../constants/ActionTypes";

const initialState = {
    existEmailInfo: {},
    clientList: {
        data: [],
        count: 0
    },
    allClientList:{
        data: [],
        count: 0
    }
}

export const clientReducer = (state = initialState, { type, payload,isReset }: any) => {
    switch (type) {
        case clientActionTypes.FATCH_EMAIL_EXIST:
            return { ...state, existEmailInfo: payload.data }
        case clientActionTypes.FETCH_CLIENT_LIST: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset
                ? queryResponse // Replace existing data if `isReset` is true
                : [...state.clientList.data, ...queryResponse]; // Append new data to existing data

            return {
                ...state,
                clientList: {
                    data: data,
                    count: payload.totalrecords || state.clientList.count, // Update count or fallback to existing count
                },
            };
        }
        case clientActionTypes.FETCH_ALL_CLIENT: {
            const queryResponse = Array.isArray(payload.queryResponse) ? payload.queryResponse : [];
            const data = isReset !== false
                ? queryResponse
                : [...state.allClientList.data, ...queryResponse];
        
            return {
                ...state,
                allClientList: {
                    data: data,
                    count: payload.totalrecords || state.allClientList.count,
                },
            };
        }
        default:
            return state

    }
} 