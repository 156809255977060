import API from "../../config/api";
import { dataActionTypes } from "../constants/ActionTypes";
import { Errors, Success } from "../../context/notify";
import { clientActionTypes } from "../constants/ActionTypes";
import config from "../../config/config";
import { serializeQueryParams } from "../../config/apiutlils";
import { DefaultParamsforUser } from "../../Typings/dataListing";

export const fetchGetAllMessages = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = '/api/Chat/GetAllMessages'
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
};

export const GetAllUsersList = (handleNext?: any) => async (dispatch: any) => {
    var path = "/api/Chat/GetAllUsersList"
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
};

export const getAllAdminList = (handleNext?: any) => async (dispatch: any) => {
    var path = "/api/Chat/getAllAdminList"
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
};

export const fetchUsersList = (handleNext?: any) => async (dispatch: any) => {
    var path = "/api/Client/Getallusers"
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};