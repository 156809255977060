import API from "../../config/api";
import { Errors, Success } from "../../context/notify";
import { clientActionTypes } from "../constants/ActionTypes";
import config from "../../config/config";
import { serializeQueryParams } from "../../config/apiutlils";
import { DefaultParamsforUser } from "../../Typings/dataListing";

export const GetClients = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.client.ClientList
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
}; 

export const fetchClientList = (defaultparams: DefaultParamsforUser, isReset: boolean = false) => async (dispatch: any) => {
    var path = config.client.ClientList
    path += serializeQueryParams(defaultparams);
    const response = await API("", false, false, !isReset).get(path);
    if (response.data.data.queryResponse) {
        dispatch({
            type: clientActionTypes.FETCH_CLIENT_LIST,
            payload: {
                queryResponse: response.data.data.queryResponse,
                totalrecords: response.data.data.totalrecords,
            },
            isReset: isReset,
        });
    } else {
        console.error("Unexpected response format:", response);
        dispatch({
            type: clientActionTypes.FETCH_CLIENT_LIST,
            payload: { queryResponse: [], totalrecords: 0 },
            isReset: isReset,
        });
    }    
}

export const fetchAllClientList = () => async (dispatch: any) => {
    const path = config.client.Getallusers;
    const response = await API("").get(path);
    const data = response?.data?.data || [];
    const totalrecords = data.length;

    dispatch({
        type: clientActionTypes.FETCH_ALL_CLIENT,
        payload: {
            queryResponse: data,
            totalrecords: totalrecords,
        },
    }); 
}

export const assignProjects = (payload: any) => async (dispatch: any) => {
    var path = config.Project.AddProjectUserTransaction
    path += serializeQueryParams(payload);
    await API().post(path).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }else{
            Errors(response.data.message);
        }
    });
}

export const AddClientDetails = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = config.client.AddClient
    await API().post(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}

export const checkEmailAvailablity = (email: any, callBack?: any) => async (dispatch: any) => {
    const response = await API("", true).get('api/Client/checkEmailAvailablity?emaill=' + email);
    if (response.status === 200) {
        callBack(response.data);
    }else{
        Errors(response.data.message);
    }
    dispatch({ type: clientActionTypes.FATCH_EMAIL_EXIST, payload: response.data });
}

export const GetClientsDetails = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.client.GetClientByClientId
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};
