import { dataActionTypes } from "../constants/ActionTypes";

const initialState = {
    pageInfo: {},
    
}

export const dataReducer = (state = initialState, { type, payload,isReset }: any) => {
    switch (type) {
        case dataActionTypes.FATCH_PAGE_INFO:
            return { ...state, pageInfo: payload }
        
        default:
            return state

    }
} 