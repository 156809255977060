import { FC, useEffect, useRef, useState } from "react";
import DashboardHeader from "../layouts/dashboardheader";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import DashboardMenu from "../layouts/dashboardmenu";
import { getProfile, updateProfile } from "../../redux/actions/accountAction";
import { FormikProps, useFormik } from "formik";
import { updateProfileParams } from "../../Typings/accountListing";
import { formFields } from "../../components/messages";
import * as Yup from "yup";
import { errorMsgs } from "../../context/notify";

const Profile: FC = () => {
    const [isActive, setIsActive] = useState(false);
    const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
    const hasFetchedRef = useRef(false);
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useSelector((state: RootState) => state.accountData.profileData);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (!hasFetchedRef.current) {
            hasFetchedRef.current = true;
            var payload =  {
                userId: parseInt(localStorage.getItem("userId") || "0", 10),
            };
            dispatch(getProfile(payload)); 
        }
    }, [dispatch]);

    const formik: FormikProps<updateProfileParams> = useFormik({
        initialValues: {
            userId: userInfo?.userId || 0,
            firstName: userInfo?.firstName || "",
            lastName: userInfo?.lastName || "",
            email: userInfo?.email || "",
            userName:userInfo?.userName || "",
            company:userInfo?.company || "",
            phoneNumber: userInfo?.phoneNumber || "",
            position: userInfo?.position || "",
            street:userInfo?.street || "",
            city: userInfo?.city || "",
            zipCode: userInfo?.zipCode || "",
            userType: userInfo?.userType || 0
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            firstName: Yup.string().required(formFields.firstName),
            lastName: Yup.string().required(formFields.lastName),
            company: Yup.string().required(formFields.company),
            phoneNumber: Yup.string().required(formFields.phone_no),
            street: Yup.string().required(formFields.street),
            position: Yup.string().required(formFields.position)
        }), 
        onSubmit: values => { 
            setIsShowLoader(true);
            dispatch(updateProfile(values, (response: any) => { handleDataSubmit(response) }));
            formik.resetForm();
        },  
    });

    
    const handleDataSubmit = (response:any) => {
        if (response.statusCode === 0) {
            errorMsgs(response.message);
        }
        setIsShowLoader(false);
        formik.resetForm();
    };

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    return ( 
        <>
           <div className={isActive ? "dbCon active" : "dbCon"}>
                <DashboardMenu />
                <div className="dbRight">
                    <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
                        <div className="dbTopHd">Update Profile</div>
                        <div className="d-flex align-items-center justify-content-between hraderRgt">
                        <DashboardHeader onClick={toggleMenu} />
                        </div>
                    </header>
                    <div className="midInner pt-4 pb-4 pt-xl-5 pb-xl-5">
                        <div className="createForm p-3 p-sm-5">
                            <form onSubmit={formik.handleSubmit}>
                                <ul className="form d-flex flex-wrap">
                                {/* First Name */}
                                <li className="col-12 col-sm-6">
                                    <span className="fieldName d-block pb-2">First Name</span>
                                    <input
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    className="formInput"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName && (
                                    <span className="error">{formik.errors.firstName}</span>
                                    )}
                                </li>

                                {/* Last Name */}
                                <li className="col-12 col-sm-6">
                                    <span className="fieldName d-block pb-2">Last Name</span>
                                    <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    className="formInput"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName && (
                                    <span className="error">{formik.errors.lastName}</span>
                                    )}
                                </li>

                                {/* Email */}
                                
                                <li className="col-12">
                                    <span className="fieldName d-block pb-2">
                                    Email Address
                                    </span>
                                    <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email address"
                                    className="formInput"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email} 
                                    disabled
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                    <span className="error">{formik.errors.email}</span>
                                    )}
                                </li>

                                {/* Company */}
                                <li className="col-12">
                                    <span className="fieldName d-block pb-2">Company</span>
                                    <input
                                    type="text"
                                    name="company"
                                    placeholder="Enter your company name"
                                    className="formInput"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.company}
                                    />
                                    {formik.touched.company && formik.errors.company && (
                                    <span className="error">{formik.errors.company}</span>
                                    )}
                                </li>
                                {/* Position */}
                                <li className="col-12 col-sm-6">
                                    <span className="fieldName d-block pb-2">Phone Number</span>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        placeholder="Enter your phone number"
                                        className="formInput"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phoneNumber}
                                    />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                        <span className="error">{formik.errors.phoneNumber}</span>
                                    )}
                                </li>


                               
                                <li className="col-12 col-sm-6">
                                    <span className="fieldName d-block pb-2">Position</span>
                                    <input
                                        type="text"
                                        name="position"
                                        placeholder="Enter your Position"
                                        className="formInput"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.position}
                                    />
                                    {formik.touched.position && formik.errors.position && (
                                        <span className="error">{formik.errors.position}</span>
                                    )}
                                </li>


                                {/* Street */}
                                <li className="col-12">
                                    <span className="fieldName d-block pb-2">Street</span>
                                    <input
                                    type="text"
                                    name="street"
                                    placeholder="Enter your street"
                                    className="formInput"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.street}
                                    />
                                    {formik.touched.street && formik.errors.street && (
                                    <span className="error">{formik.errors.street}</span>
                                    )}
                                </li>
                                {/* Zip Code */}
                                <li className="col-12 col-sm-4">
                                    <span className="fieldName d-block pb-2">Zip Code</span>
                                    <input
                                    type="text"
                                    name="zipCode"
                                    placeholder="Enter your Zip Code"
                                    className="formInput"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.zipCode}
                                    />
                                    {formik.touched.zipCode && formik.errors.zipCode && (
                                    <span className="error">{formik.errors.zipCode}</span>
                                    )}
                                </li>

                                {/* City */}
                                <li className="col-12 col-sm-8">
                                    <span className="fieldName d-block pb-2">City</span>
                                    <input
                                    type="text"
                                    name="city"
                                    placeholder="Enter your City"
                                    className="formInput"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    />
                                    {formik.touched.city && formik.errors.city && (
                                    <span className="error">{formik.errors.city}</span>
                                    )}
                                </li>

                                {/* Buttons */}
                                <li className="col-12 d-flex justify-content-end">
                                    <button
                                    type="submit"
                                    className="formBtn position-relative ms-3"
                                    >
                                    {isShowLoader ? <div className="loader"></div> : "Save"}
                                    </button>
                                </li>
                                </ul>
                            </form> 
                        </div>
                    </div>
                </div>
            </div> 
        </>
    );
};

export default Profile;
