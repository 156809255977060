import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/img/footer-logo.png';
import Facebook from '../../assets/img/facebook.png';
import Youtube from '../../assets/img/youtube.png';
import Linkedin from '../../assets/img/linkedin.png';
import Skype from '../../assets/img/skype.png';

const Footer: FC = () => {
    
    return (
        <>
            <footer className="footer pt-sm-5 pt-4 pb-xs-5 pb-4">
                <div className="container">
                    <div className="d-flex flex-wrap footerTop">
                        <div className="col-lg-4 col-12 culumn">
                            <div className="footerLogo"><img alt="" src={Logo} /></div>
                            <div className="footerText pt-4">Improve efficiency, provide a better Customer experience with modern Technolo services available.</div>
                        </div>
                        <div className="col-lg-3 col-xs-5 col-12 culumn d-flex justify-content-lg-center justify-content-start">
                            <div className="callInfo position-relative">
                                <span className="icon"><span className="material-icons">phone_enabled</span></span>
                                <span className="d-block">Give us a call</span>
                                +123-456-7890
                            </div>
                        </div>
                        <div className="col-lg-5 col-xs-7 col-12 culumn">
                            <div className="d-flex flex-wrap align-items-center">
                                <div className="newsletterHd">Join Newsletter</div>
                                <div className="newsletter position-relative"><input type="text" placeholder="Your email" className="input" /><button type="button" className="btn">Submit</button></div>
                            </div>
                            {/* <div className="pt-4">
                                <div className="followusHd pb-2">Follow Us</div>
                                <ul className="d-flex align-items-center social">
                                    <li><a href="#" title="Facebook"><img alt="Facebook" src={Facebook} /></a></li>
                                    <li><a href="#" title="Youtube"><img alt="Youtube" src={Youtube} /></a></li>
                                    <li><a href="#" title="Linkedin"><img alt="Linkedin" src={Linkedin} /></a></li>
                                    <li><a href="#" title="Skype"><img alt="Skype" src={Skype} /></a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="copyright text-center pt-sm-4 pt-3 mt-xs-5 mt-4">&copy; Copyright 2024 All rights reserved.</div>
                </div>
            </footer>            
        </>        
    );
}
export default Footer;