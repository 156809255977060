import { FC, useEffect, useState } from "react";
import DashboardMenu from "../layouts/dashboardmenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllNewTicketetQuotation,
  getTicketRequest,
  setTicketApproval,
} from "../../redux/actions/ticketAction";
import { Button, Dialog, DialogActions } from "@mui/material";
import { PageSize } from "../../config/constant";
import Pagination from "../../components/pagination";
import { bool, boolean, number } from "yup";
import Loader from "../../components/Loader";
import { fetchAllClientList } from "../../redux/actions/clientAction";

const QuotationList: FC = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { ticketId } = useParams<{ ticketId: string }>();
  const [openViewQuotation, setopenViewQuotation] = useState(false);
  const [QuotationViewData, setQuotationViewData] = useState([]);
  const allClientList = useSelector(
    (state: RootState) => state.clientData.allClientList
  );
  const [isLoading, setIsLoading] = useState(true);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [clientId, SetClientId] = useState(
    localStorage.getItem("role") === "Admin"
      ? 0
      : parseInt(localStorage.getItem("userId") || "0", 10)
  );
  const [quotationInfo, setQuotationInfo] = useState(number);
  const [QuotationStatus, SetQuotationStatus] = useState(boolean);
  const [pageSizeSelect, setPageSizeSelect] = useState(PageSize);
  const [quotationDataList, setQuotationDataList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsLoading(true);
    getQuotationList();
    dispatch(fetchAllClientList());
  }, [clientId, CurrentPage, pageSizeSelect]);

  const getQuotationList = () => {
    var payload = {
      UserId: clientId,
      PageNumber: CurrentPage,
      PageSize: pageSizeSelect,
    };

    try {
      dispatch(
        getAllNewTicketetQuotation(payload, (response: any) => {
          setQuotationDataList(response.data);
        })
      );
    } catch (error) {
      console.error("Error fetching tickets:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproval = (quoteid: any, isApprove: boolean) => {
    var payload = {
      QuotationId: quoteid,
      ApprovalAction: isApprove,
    };
    dispatch(
      setTicketApproval(payload, (response: any) =>
        callbackTicketApproval(response)
      )
    );
  };

  function callbackTicketApproval(response: any) {
    getQuotationList();
  }
  const handleViewQuotation = (quoId: any, datas: any, QuotStatus: any) => {
    setQuotationInfo(quoId);
    setQuotationViewData(datas);
    SetQuotationStatus(QuotStatus);
    setopenViewQuotation(true);
  };

  const handleClose = () => {
    setopenViewQuotation(false);
  };

  const updateQuotation = () => {
    navigate("/newTickets", { state: { quotationInfo } });
  };

  const getQuotationViewDetails = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openViewQuotation}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="p-3 ticket-details">
          <div className="d-flex justify-content-between">
            <div className="d-block w-100">
              <h4 className="board-title-h4 mb-3">View Quotation Details</h4>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Subject</th>
                    <th>Priority</th>
                    <th>Project Name</th>
                  </tr>
                </thead>
                <tbody>
                  {QuotationViewData.length > 0 ? (
                    QuotationViewData.map((quote: any, index: any) =>
                      quote.status == 1 ? (
                        <tr key={quote.ticketId}>
                          <td className="text-danger">{index + 1}</td>
                          <td className="text-danger">{quote.subject}</td>
                          <td className="text-danger">{quote.priority}</td>
                          <td className="text-danger">{quote.projectName}</td>
                        </tr>
                      ) : (
                        <tr key={quote.ticketId}>
                          <td>{index + 1}</td>
                          <td>{quote.subject}</td>
                          <td>{quote.priority}</td>
                          <td>{quote.projectName}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={5}>No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <p className="text-success">
                Note* : Text Display in Red Color show that Ticket has already
                been assigned to someone
              </p>
              <DialogActions>
                <div className="float-end">
                  {localStorage.getItem("role") == "Client" &&
                  !QuotationStatus ? (
                    <Button
                      type="button"
                      className="me-2 save-button btn-success"
                      autoFocus
                      onClick={() => updateQuotation()}
                    >
                      Update
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    type="button"
                    className="close-button btn-warning"
                    autoFocus
                    onClick={() => setopenViewQuotation(false)}
                  >
                    Close
                  </Button>
                </div>
              </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleClientStatus = (event: any) => {
    const selectedClientId = event.target.value;
    SetCurrentPage(1);
    SetClientId(selectedClientId); // Update priorityId state
    getQuotationList(); // Pass the updated priorityId
  };

  const handlePageChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10);
    setPageSizeSelect(selectedPageSize); // Update page size
    SetCurrentPage(1); // Reset to the first page
  };
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">
              {location.state?.Ticketname
                ? "Quotation List for " + location.state?.Ticketname
                : "Quotation List"}
            </div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4"></div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-3 pb-xl-3">
            <div className="filterCon d-flex flex-wrap align-items-center mt-1 mt-xl-2">
              {localStorage.getItem("role") === "Admin" ? (
                <div className="filterSearch ms-3">
                  <select
                    className="form-control project-filter"
                    onChange={handleClientStatus}
                    name="seacrhByProjectStatus"
                    id="seacrhByProjectStatus"
                  >
                    <option value={0}>Search By Client</option>
                    {allClientList.data.map((clientData: any) => (
                      <option key={clientData.userid} value={clientData.userid}>
                        {clientData.fullName}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}
              <div className="filterSearch ms-3">
                <select
                  className="form-control project-filter"
                  onChange={handlePageChange}
                  name="pageChange"
                  id="pageChange"
                  value={pageSizeSelect}
                >
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div className="tableCov mt-4 mt-xl-4">
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      {localStorage.getItem("role") === "Admin" ? (
                        <th>Sender Name</th>
                      ) : (
                        ""
                      )}
                      <th>Quotation</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={5} className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    quotationDataList.queryResponse && (
                      <tbody>
                        {quotationDataList.queryResponse.map(
                          (item: any, index: any) => (
                            <tr key={`Quotation_${item.quotationId}`}>
                              <td>{index + 1}</td>
                              {localStorage.getItem("role") === "Admin" ? (
                                <td>{item.userName}</td>
                              ) : (
                                ""
                              )}
                              <td>{item.quotation}</td>
                              <td>
                                {item.description.length > 40
                                  ? `${item.description.substring(0, 40)}...`
                                  : item.description}
                              </td>
                              <td>
                                {new Date(item.createdOn).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-info"
                                  onClick={() =>
                                    handleViewQuotation(
                                      item,
                                      item.ticketResquetModels,
                                      item.isApproved
                                    )
                                  }
                                >
                                  <i className="fa fa-eye"></i>
                                </button>
                                {localStorage.getItem("role") === "Admin" ? (
                                  !item.isApproved ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-success ms-1"
                                        onClick={() =>
                                          handleApproval(item.quotationId, true)
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn btn-sm btn-warning ms-1"
                                        onClick={() =>
                                          handleApproval(
                                            item.quotationId,
                                            false
                                          )
                                        }
                                      >
                                        Disapprove
                                      </button>
                                    </>
                                  ) : (
                                    <span className="ms-2">Approved </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    )
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={quotationDataList.pageIndex}
                PageSize={quotationDataList.pageSize}
                TotalPages={quotationDataList.totalPages}
                TotalCount={quotationDataList.totalrecords}
                hasNextPage={quotationDataList.hasNextPage}
                hasPreviousPage={quotationDataList.hasPreviousPage}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">{getQuotationViewDetails()}</div>
      </div>
    </>
  );
};

export default QuotationList;
