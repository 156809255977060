import React from "react";


const Pagination = (props :any
) => {

  const pageNumber =(pageIndex : number ,totalPages :number) =>{
   
    const list=[];
    if(totalPages<=7)
      for(let index = 1; index <= totalPages ; index++) {
        if(pageIndex == index){
          list.push(<li key={"li_"+index}><a className="pagination-link is-current" aria-label={"Page " +index} onClick={()=>{props.GoToPage(index)}} aria-current="page">{index}</a></li>)
        }else{
            list.push(<li  key={"li_"+index}><a className="pagination-link" aria-label={"Goto page "+ index } onClick={()=>{props.GoToPage(index)}}>{index}</a></li>)        
        }
      }
    else{

        var startIndex = pageIndex==1 || pageIndex == totalPages ? Math.floor(totalPages/2)-1 : pageIndex == 2 ? 2 :pageIndex == totalPages-1 ? totalPages-3 :  pageIndex -1;
        startIndex =(totalPages-pageIndex) == 2 ? startIndex-1 : pageIndex== 3? startIndex +1 : startIndex
        
        if(pageIndex==1){
          list.push(<li  key={"li_1"}><a className=" is-current" aria-label="Page 1" aria-current="page" onClick={()=>{props.GoToPage(1)}} >1</a></li>)
       
        }else{
          list.push(<li  key={"li_1"}><a aria-label="Goto page 1" onClick={()=>{props.GoToPage(1)}}>{1}</a></li>)  
        }
        if(pageIndex != 2)
          list.push( <li key={"li_.."}><span className="pagination-ellipsis">&hellip;</span></li>);
        for(let index = startIndex; index < startIndex+ 3 ; index++) {
          if(pageIndex == index){
            list.push(<li  key={"li_"+index}><a className="is-current" aria-label={"Page " +index} onClick={()=>{props.GoToPage(index)}} aria-current="page">{index}</a></li>)
          }else{
              list.push(<li  key={"li_"+index}><a  aria-label={"Goto page "+ index } onClick={()=>{props.GoToPage(index)}}>{index}</a></li>)        
          }
        }
        
        if(pageIndex != (totalPages-1))
          list.push(   <li  key={"li_..."}><span className="pagination-ellipsis">&hellip;</span></li>);
        if(pageIndex==totalPages){
          list.push(<li  key={"li_"+totalPages}><a  aria-label={"Page " +totalPages} onClick={()=>{props.GoToPage(totalPages)}}   aria-current="page">{totalPages}</a></li>)
       
        }else{
          list.push(<li  key={"li_"+totalPages}><a aria-label={"Goto page "+ totalPages } onClick={()=>{props.GoToPage(totalPages)}}>{totalPages}</a></li>)  
        }
      }
   
      return (<>{list}</>  )  
  }
  return (
    <> <div className="paging p-4 d-flex justify-content-end">
           { props.hasPreviousPage? 
             <li><a  onClick={()=>{props.GoToPage(props.PageIndex - 1)}}>Prev</a></li>:null }
          
            {props?  <ul className="d-flex">
             {pageNumber(props.PageIndex,props.TotalPages)}
            </ul> :
          null
            }
              {props.hasNextPage ? <li><a href="#" onClick={()=>{props.GoToPage(props.PageIndex + 1)}}>Next</a></li> :null}
        </div>
    </>
  )
}
export default Pagination
