import { combineReducers } from "redux";
import { accountReducer } from "./accountReducer";
import { clientReducer } from "./clientReducer";
import { projectReducer } from "./projectReducer";

const reducers = combineReducers({
    accountData : accountReducer,
    clientData: clientReducer,
    projectData: projectReducer
});
export default reducers;