import { FC, useState, useEffect } from "react";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { fetchPowerBiTokenData } from "../../redux/actions/accountAction";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";

const Dashboard: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    fetchPowerBiToken()
  }, []);

  const fetchPowerBiToken = async () => {
    const UserCode = localStorage.getItem("userId");
    const parsedUserCode = UserCode ? parseInt(UserCode, 10) : null;
    const payload = {
      UserId: parsedUserCode,
    };

    dispatch(
      fetchPowerBiTokenData(payload, (response: any) => {
        if (response.data) {
          localStorage.setItem(
            "powerbiToken",
            response.data.powerBiAccessToken
          );
          setAccessToken(
            response.data.powerBiAccessToken
              ? response.data.powerBiAccessToken
              : ""
          );
        }
      })
    );
  };

  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Dashboard</div>
            <div className="d-flex align-items-center justify-content-between headerRgt">
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>

          {/* Embed Power BI Report */}
          {accessToken && (
            <div>
              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Correct type for embedding a report

                  id: "437133d2-400c-4a6d-bebe-06f4b7254f13", // Report ID

                  embedUrl:
                    "https://app.powerbi.com/reportEmbed?reportId=437133d2-400c-4a6d-bebe-06f4b7254f13&groupId=ac93bd7e-5bb2-44e3-b85d-8c70c2baa1ce&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtQi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",

                  accessToken: accessToken, // Access token from backend

                  tokenType: models.TokenType.Embed, // Azure AD Token

                  settings: {
                    panes: {
                      filters: { visible: false }, // Hide filters pane

                      pageNavigation: { visible: false }, // Hide page navigation pane
                    },
                  },
                }}
                cssClassName={"report"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
