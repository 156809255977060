import { combineReducers } from "redux";
 import { dataReducer } from "./dataReducer";
// import { videoReducer } from "./videoReducer";
// import { imageReducer } from "./imageReducer";
import { accountReducer } from "./accountReducer";

const reducers = combineReducers({
    allData : dataReducer,
   // videoData : videoReducer,
  //  imageData : imageReducer,
    accountData : accountReducer
});
export default reducers;