import { FC, useEffect, useRef } from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
// import Header2 from "../layouts/header2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getProfile, updateProfile } from "../../redux/actions/accountAction";
import { formFields } from "../../components/messages";
import * as Yup from 'yup';
import { FormikProps, useFormik } from "formik";
import {  updateProfileParams } from "../../Typings/accountListing";
import { handleError } from "../../context/notify";

const ClientList: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useSelector((state: RootState) => state.accountData.profileData);
    const hasFetchedRef = useRef(false);
    
    useEffect(() => {
        if (!hasFetchedRef.current) {
            hasFetchedRef.current = true;
          
        }
    }, [dispatch]);

   

   

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    return ( 
        <>
            <Header />
           
            <div className="section">
               
            </div>
            <Footer />
        </>
    );
};

export default ClientList;
