import { FC, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import Project from "../../assets/img/total-project.png";
import ActiveProject from "../../assets/img/active-projects.png";
import InActiveProject from "../../assets/img/in-active-projects.png";
import ProjectStatus from "../../assets/img/project-status.png";
import Edit from "../../assets/img/edit.png";
import x from "../../assets/img/x.svg";
import Delete from "../../assets/img/delete.png";
import Pagination from "../../components/pagination";
import { Link } from "react-router-dom";
import { bool, number } from "yup";
import { AppDispatch } from "../../redux/store";
import { PageSize } from "../../config/constant";
import { GetProjects } from "../../redux/actions/ProjectAction";
const ManageProject: FC = () => {
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [ProjectList, SetProjectList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  const dispatch = useDispatch<AppDispatch>();
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    searchProject();
  }, [CurrentPage, searchQuery]);

  const searchProject = () => {
    var payload = {
      PageNumber: CurrentPage,
      PageSize: PageSize,
      Search: searchText,
    };
    // console.log("search....", payload);
    dispatch(
      GetProjects(payload, (response: any) => {
        SetProjectList(response.data);
      })
    );
  };

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Projects</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4">
                <NavLink
                  to="/project/create-project"
                  className="createLink d-inline-flex align-items-center"
                >
                  Create
                </NavLink>
              </div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-5 pb-xl-5">
            <ul className="d-flex flex-wrap projectTopInfo">
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={Project} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Total Projects <span style={{ color: "#115D8B" }}>11</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 175, 83, 0.19)" }}
                  >
                    <img src={ActiveProject} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    Active Projects <span style={{ color: "#11AF53" }}>7</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(139, 28, 17, 0.25)" }}
                  >
                    <img src={InActiveProject} alt="" />
                  </div>
                  <div className="det pt-3 d-flex justify-content-between align-items-center">
                    In Active Projects{" "}
                    <span style={{ color: "#CE4523" }}>3</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="inner d-flex align-items-center p-4 projectStatus">
                  <div className="left">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: "rgba(255, 233, 198, 1)" }}
                    >
                      <img src={ProjectStatus} alt="" />
                    </div>
                    <div className="det text-center pt-4">Project Status</div>
                  </div>
                  <div className="right ps-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>In Progress</span>
                      <span className="rgt" style={{ color: "#FF6600" }}>
                        4
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Pending</span>
                      <span className="rgt" style={{ color: "#115D8B" }}>
                        4
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Done</span>
                      <span className="rgt" style={{ color: "#138944" }}>
                        3
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="filterCon d-flex flex-wrap align-items-center mt-4 mt-xl-5">
              <div className="filterBox filterSearchBox">
                <div className="filterSearch">
                  <input
                    type="text"
                    onChange={(event) => setSearchText(event.target.value)}
                    className="input"
                    placeholder="Search..."
                  />
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      SetCurrentPage(1);
                      searchProject();
                    }}
                  >
                    <span className="material-icons">search</span>
                  </button>
                </div>
              </div>
              <div className="filterBox">
                <a href="#" className="sortBy d-flex align-items-center">
                  <span className="material-icons me-3">tune</span> Sort by
                </a>
              </div>
              <div className="filterBox filterSelectBox">
                <select className="filterSelect">
                  <option>Project Name</option>
                </select>
              </div>
              <div className="filterBox filterSelectBox">
                <select className="filterSelect">
                  <option>Status</option>
                </select>
              </div>
              <div className="filterBox filterSelectBox">
                <select className="filterSelect">
                  <option>City</option>
                </select>
              </div>
              <div className="filterBox filterSelectBox">
                <select className="filterSelect">
                  <option>Project no.</option>
                </select>
              </div>
              <div className="filterBox filterSelectBox">
                <select className="filterSelect">
                  <option>Active</option>
                </select>
              </div>
            </div>
            <div className="tableCov mt-4 mt-xl-5">
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th>Project Name</th>
                      <th>Project Number</th>
                      <th>Description</th>
                      <th>City</th>
                      <th>Street</th>
                      <th>Zipcode</th>
                      <th>Project Start date</th>
                      <th>Project End date</th>
                      <th>Active</th>
                      <th>Change status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {ProjectList.queryResponse && (
                    <tbody>
                      {ProjectList.queryResponse.map((item: any) => (
                        <tr key={"Project" + item.projectId}>
                          <td>{item.name}</td>
                          <td>{item.projectNumber}</td>
                          <td>{item.description}</td>
                          <td>{item.city}</td>
                          <td>{item.street}</td>
                          <td>{item.zipcode}</td>
                          <td>
                            {new Date(item.projectStartDate).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td>
                            {new Date(item.projectEndDate).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td>
                            {item.isActive ? (
                              <span className="yes">Yes</span>
                            ) : (
                              <span className="no">No</span>
                            )}
                          </td>
                          <td>
                          <Link
                                to={{
                                  pathname:
                                    "/project/create-project/" + item.projectId,
                                }}
                                className="editIcon"
                              >
                                <img src={x} alt="Change active" />
                              </Link>
                          </td>
                          <td>
                            <div className="btnBox d-inline-flex align-items-center">
                              <Link
                                to={{
                                  pathname:
                                    "/project/create-project/" + item.projectId,
                                }}
                                className="editIcon"
                              >
                                <img src={Edit} alt="Edit" />
                              </Link>
                              <Link
                                to={{
                                  pathname: "/partner-detail",
                                  search: item.projectId.toString(),
                                }}
                                className="viewIcon"
                              >
                                <img src={Delete} alt="Delete" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={ProjectList.pageIndex}
                PageSize={ProjectList.pageSize}
                TotalPages={ProjectList.totalPages}
                TotalCount={ProjectList.totalrecords}
                hasNextPage={ProjectList.hasNextPage}
                hasPreviousPage={ProjectList.hasPreviousPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProject;
