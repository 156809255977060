import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers/index";
import {thunk} from "redux-thunk";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const initialState = {};
const middleware = [thunk];
const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middleware))
    
);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;