import { PowerBIEmbed } from "powerbi-client-react";

export default {
    Admin: {
        TicketApproval: "api/Admin/TicketApproval"
    },
    Master: {
        GetIssuePriorities: "api/Masters/GetIssuePriorities",
        GetIssueStatus: "api/Masters/GetIssueStatus",
        GetTicketType: "api/Masters/GetTicketType"
    },
    KPI: {
        GetChartsData: "api/KPI/GetChartsData"
    },
    client: {
        ClientList: "api/Client/GetAllUsersList",
        AddClient: "api/Admin/AddUpdateUserDetails",
        GetClientByClientId: "api/Client/GetUserByUserId",
        Getallusers: "api/Client/Getallusers"
    },
    notification: {
        getUsersNotification: "api/Notification/getUsersNotification",
        ReadNotification: "api/Notification/ReadNotification"
    },
    TicketRequestQuotation: {
        postUserTicketRequest: "api/TicketRequestQuotation/postUserTicketRequest",
        GetQuotation: "api/TicketRequestQuotation/GetQuotation",
        ApproveQuotationRequest: "api/TicketRequestQuotation/ApproveQuotationRequest",
        UpdateQuotation: "api/TicketRequestQuotation/UpdateQuotation"
    },
    Project: {
        ProjectList: "api/Project/getAllProjects",
        AddUpdateProject: "api/Project/AddUpdateProjectDetails",
        GetProjectDetails: "api/Project/getProjectsbyProjectId",
        ActiveDeactiveProject: "api/Project/ActiveDeactiveProject",
        CheckProjectNameandNumber: "api/Project/CheckProjectNameandNumber",
        AddProjectUserTransaction: "api/Project/AddProjectUserTransaction",
        deleteProject: "api/Project/deleteProject",
        getParentsProjects: "api/Project/getParentsProjects",
        getprojectUserIdAsync: "api/Project/getprojectUserIdAsync"
    },
    Tickets: {
        TicketList: "api/Ticket/GetTicketListingPage",
        UserTicketRequest: "api/Ticket/UserTicketRequest",
        getAllTicketAsync: "api/Ticket/getAllTicketAsync",
        GetAllTicketByProjectId: "api/Ticket/GetAllTicketByProjectId",
        getTicketRequest: "api/Ticket/getTicketRequest",
        UpdateProjectStatus: "api/TicketStatus/UpdateProjectStatus",
        UpdateTicketType: "api/Ticket/UpdateTicketType",
        GetAlltickets: "api/Ticket/GetAlltickets",
        GetTicketStatusByTicketId: "api/Ticket/GetTicketStatusByTicketId"
    },
    PowerBI: {
        GetPoweBiToken: "api/LoginAuth/GeneratePowerBiToken"
    }
};