import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo.png';
import Dashboard from '../../assets/img/dashboard.png';
import Clients from '../../assets/img/clients.png';
import Projects from '../../assets/img/projects.png';
import Tickets from '../../assets/img/tickets.png';
import Logout from '../../assets/img/logout.png';

const DashboardMenu: FC = () => {
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState('');
    const role = localStorage.getItem("role"); // Get role from localStorage
  
    const toggleSubmenu = (menuName: string) => {
        setActiveMenu((prevMenu) => (prevMenu === menuName ? '' : menuName));
    };

    return (
        <>
            <div className="dbLeft">
                <div className="dbLogo"><img src={Logo} alt="" /></div>
                <div className="dbMenu">
                    <ul>
                        {(localStorage.getItem("role") === "Admin" && localStorage.getItem("userId")) ? 
                         
                            <li><NavLink to="/dashboard"><img src={Dashboard} alt="" /> Dashboard</NavLink></li>
                            :""}
                        {(localStorage.getItem("role") === "Admin" && localStorage.getItem("userId")) ?
                            <li><NavLink to="/client"><img src={Clients} alt="" /> Clients</NavLink></li>
                            :""}
                        <li><NavLink to="/project"><img src={Projects} alt="" /> Projects</NavLink></li>
                        <li>
                            <a
                                onClick={() => toggleSubmenu('tickets')}
                                className={activeMenu === 'tickets' ? 'active' : ''}
                            >
                                <img src={Tickets} alt="" /> Tickets <span className="material-icons arrow">expand_more</span>
                            </a>
                            <ul className={activeMenu === 'tickets' ? 'subLink active' : 'subLink'}>
                                <li><NavLink to="/tickets">Tickets</NavLink></li>
                                <li><NavLink to="/newTickets">New Tickets</NavLink></li>
                            </ul>
                        </li>
                        <li>
                            <a
                                onClick={() => toggleSubmenu('settings')}
                                className={activeMenu === 'settings' ? 'active' : ''}
                            >
                                <i className="fa fa-cog ms-1 me-3"></i> Settings <span className="material-icons arrow">expand_more</span>
                            </a>
                            <ul className={activeMenu === 'settings' ? 'subLink active' : 'subLink'}>
                                <li><NavLink to="/update-profile">My Profile</NavLink></li>
                                <li><NavLink to="/change-password">Change Passwords</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li className="logout">
                            <a href="#" onClick={() => navigate("/")}>
                                <img src={Logout} alt="" /> Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default DashboardMenu;
