import API from "../../config/api";
import { errorMsgs, Errors, Success } from "../../context/notify";
import { dataActionTypes, projectActionTypes } from "../constants/ActionTypes";
import config from "../../config/config";
import { serializeQueryParams } from "../../config/apiutlils";
import { DefaultParamsforProject } from "../../Typings/dataListing";

export const GetProjects = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.Project.ProjectList
    path += serializeQueryParams(payload);
    await API().get(path).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const GetprojectDetails = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = config.Project.GetProjectDetails
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });
}; 

export const deleteProject = (payload: any) => async (dispatch: any) => {
    var path = config.Project.deleteProject
    path += serializeQueryParams(payload);
    await API().delete(path).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
}

export const UpdateProjectStatus = (payload:any) => async () =>{
    var path = config.Tickets.UpdateProjectStatus
    path += serializeQueryParams(payload);
    await API().patch(path).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
}
export const UserTicketRequest = (datas: any) => async (dispatch: any) => {
    var path = config.Tickets.UserTicketRequest
    await API().post(path+'?userId='+ datas.userId+'&ticketId=' + datas.ticketId+'&quotation=' +datas.quotation+'&Description=' +datas.description).then((response: any) => {
        if (response.status === 200) {
            Success(response.data.message);
        }
    }).catch((err) => {
        errorMsgs("Something went wrong");
    });
} 

export const getAllProject = () => async (dispatch: any) => {
    var path = config.Project.ProjectList
    const response = await API("").get(path);
    if (response.data.data.queryResponse) {
        dispatch({
            type: projectActionTypes.FETCH_PROJECT_LIST,
            payload: {
                queryResponse: response.data.data.queryResponse,
                totalrecords: response.data.data.totalrecords,
            },
        });
    } else {
        dispatch({
            type: projectActionTypes.FETCH_PROJECT_LIST,
            payload: { queryResponse: [], totalrecords: 0 },
        });
    }    
}

export const fetchProjectList = (defaultparams: DefaultParamsforProject, isReset: boolean = false) => async (dispatch: any) => {
    var path = config.Project.ProjectList
    path += serializeQueryParams(defaultparams);
    const response = await API("", false, false, !isReset).get(path);
    if (response.data.data.queryResponse) {
        dispatch({
            type: projectActionTypes.FETCH_PROJECT_LIST,
            payload: {
                queryResponse: response.data.data.queryResponse,
                totalrecords: response.data.data.totalrecords,
            },
            isReset: isReset,
        });
    } else {
        dispatch({
            type: projectActionTypes.FETCH_PROJECT_LIST,
            payload: { queryResponse: [], totalrecords: 0 },
            isReset: isReset,
        });
    }    
}

export const AddProjectDetails = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = config.Project.AddUpdateProject;
    await API().post(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
} 

export const activeDeactiveProject = (datas: any, callBack?: any) => async (dispatch: any) => {
    var path = config.Project.ActiveDeactiveProject;
    await API().patch(path+'?AppProjectId=' + datas.AppProjectId+'&status=' + datas.status+'&userId=' +datas.userId).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response);
        }
    }).catch((err) => {
        callBack(err);
    });
}

export const checkProjectNameandNumber = (datas: any, callBack?: any) => async (dispatch: any) => {
    var path = config.Project.CheckProjectNameandNumber;
    const response = await API("", true).get(path +'?ProjectName='+ datas.ProjectName+'&projectnumber=' +datas.projectnumber);
    if (response.status === 200) {
        callBack(response.data);
    }else{
        Errors(response.data.message);
    }
}


export const getprojectUserIdAsync = (datas: any, callBack?: any) => async (dispatch: any) => {
    var path = config.Project.getprojectUserIdAsync;
    const response = await API("", true).get(path +'?Userid='+ datas.Userid+'&Projectid=' +datas.Projectid);
    if (response.data && callBack) {
        callBack(response.data); // Ensure callBack is called only if it exists
    }
}

export const getParentsProjects =  (UserId: any) => async (dispatch: any) => {
    const path = config.Project.getParentsProjects;
    const response = await API("").get(path+'?userId='+ UserId);
    const data = response?.data?.data.projectDetails || [];
    const totalrecords = data.length;

    dispatch({
        type: dataActionTypes.FETCH_PARENT_PROJECT,
        payload: {
            queryResponse: data,
            totalrecords: totalrecords,
        },
    });  
}