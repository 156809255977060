import { FC, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardHeader from "../layouts/dashboardheader";
import DashboardMenu from "../layouts/dashboardmenu";
import Companies from "../../assets/img/total-companies.png";
import Revenue from "../../assets/img/total-revenue.png";
import ProfitRate from "../../assets/img/profit-rate.png";
import Edit from "../../assets/img/edit.png";
import Delete from "../../assets/img/delete.png";
import { PageSize } from "../../config/constant";
import { GetClients } from "../../redux/actions/clientAction";
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination";
import { bool, number } from "yup";
const ManageClient: FC = () => {
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [clientList, SetClientList] = useState({
    queryResponse: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  const dispatch = useDispatch<AppDispatch>();
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    searchClients();
  }, [CurrentPage, searchQuery]);

  const searchClients = () => {
    var payload = {
      pageIndex: CurrentPage,
      PageSize: PageSize,
      SearchText: searchText,
    };
    console.log("search....", payload);
    dispatch(
      GetClients(payload, (response: any) => {
        // console.log("result");
        // console.log(response.data);
        SetClientList(response.data);
      })
    );
  };

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className={isActive ? "dbCon active" : "dbCon"}>
        <DashboardMenu />
        <div className="dbRight">
          <header className="dbHeader d-flex flex-wrap justify-content-between align-items-center">
            <div className="dbTopHd">Clients</div>
            <div className="d-flex align-items-center justify-content-between hraderRgt">
              <div className="me-2 me-md-4">
                <NavLink
                  to="/client/create-client"
                  className="createLink d-inline-flex align-items-center"
                >
                  Create
                </NavLink>
              </div>
              <DashboardHeader onClick={toggleMenu} />
            </div>
          </header>
          <div className="midInner pt-4 pb-4 pt-xl-5 pb-xl-5">
            <ul className="d-flex flex-wrap topInfo">
              <li className="col-12 col-sm-6 col-lg-4">
                <div className="inner d-flex align-items-center p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 93, 139, 0.25)" }}
                  >
                    <img src={Companies} alt="" />
                  </div>
                  <div className="det ps-4">
                    Total Clients <span style={{ color: "#115D8B" }}>07</span>
                  </div>
                  <img src={Companies} alt="" className="bg" />
                </div>
              </li>
              <li className="col-12 col-sm-6 col-lg-4">
                <div className="inner d-flex align-items-center p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(17, 175, 83, 0.19)" }}
                  >
                    <img src={Revenue} alt="" />
                  </div>
                  <div className="det ps-4">
                    Total Admin{" "}
                    <span style={{ color: "#11AF53" }}>$12,432</span>
                  </div>
                  <img src={Revenue} alt="" className="bg" />
                </div>
              </li>
              <li className="col-12 col-sm-6 col-lg-4">
                <div className="inner d-flex align-items-center p-4">
                  <div
                    className="icon d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(255, 233, 198, 1)" }}
                  >
                    <img src={ProfitRate} alt="" />
                  </div>
                  <div className="det ps-4">
                    Profit Rate <span style={{ color: "#FF6600" }}>80%</span>
                  </div>
                  <img src={ProfitRate} alt="" className="bg" />
                </div>
              </li>
            </ul>
            <div className="filterCon d-flex flex-wrap align-items-center mt-4 mt-xl-5">
              <div className="filterBox filterSearchBox">
                <div className="filterSearch">
                  <input
                    type="text"
                    onChange={(event) => setSearchText(event.target.value)}
                    className="input"
                    placeholder="Search..."
                  />
                  <button type="button" className="btn">
                    <span
                      className="material-icons"
                      onClick={() => {
                        SetCurrentPage(1);
                        searchClients();
                      }}
                    >
                      search
                    </span>
                  </button>
                </div>
              </div>
              {/*  <div className="filterBox">
                                <a href="#" className="sortBy d-flex align-items-center"><span className="material-icons me-3">tune</span> Sort by</a>
                            </div>
                            <div className="filterBox filterSelectBox">
                                <select className="filterSelect">
                                    <option>Company Name</option>
                                </select>
                            </div>
                           */}
            </div>
            <div className="tableCov mt-4 mt-xl-5">
              <div className="tableBox">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Street</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {clientList.queryResponse && (
                    <tbody>
                      {clientList.queryResponse.map((item: any) => (
                        <tr key={"Client" + item.userId}>
                          <td>{item.company}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.phonenumber}</td>
                          <td>{item.street}</td>
                          {/*<td><ReactSwitch checked={partner.isActive} onChange={() => updateStatusPartner(partner.partnerId,partner.isActive)} /></td>*/}
                          <td>
                            {/* <CreatePartner Partner= {partner} onSuccess={searchPartners}/>
                                      <CreatePartner /> 
                                        */}
                            <div className="btnBox d-inline-flex align-items-center">
                              <Link
                                to={{
                                  pathname:
                                    "/client/create-client/" + item.userId,
                                }}
                                className="editIcon"
                              >
                                <img src={Edit} alt="Edit" />
                              </Link>
                              <Link
                                to={{
                                  pathname: "/partner-detail",
                                  search: item.userId.toString(),
                                }}
                                className="viewIcon"
                              >
                                <img src={Delete} alt="Delete" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
              <Pagination
                GoToPage={(value: any) => {
                  SetCurrentPage(value);
                }}
                PageIndex={clientList.pageIndex}
                PageSize={clientList.pageSize}
                TotalPages={clientList.totalPages}
                TotalCount={clientList.totalrecords}
                hasNextPage={clientList.hasNextPage}
                hasPreviousPage={clientList.hasPreviousPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClient;
